import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';

const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]


import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';

import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export interface Props {
  match?: any
}

export const HistoriDetail = (props: Props) => {
  // const { account } = props;

  const [category, setCategory] = useState<any>(0)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(5)
  const [selected, setSelected] = useState(list[0])
  const [listDocumentHistoriDetail, setListDocumentHistoriDetail] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [documentSelected, setDocumentSelected] = useState<any>(null)
  const [loadingButton, setLoadingButton] = useState<boolean>(false)


  const handleClose = () => {
    setDocumentSelected(null)
    setShowModal(false)
  };

  const handleOpen = (item) => {
    setDocumentSelected(item)
    setShowModal(true)
  }

  useEffect(() => {
      getListDocumentHistoriDetail(props.match.params.id)
      
  }, []);
  



  


  const renderStatusPriority = (data) => {
    if(data == "1"){
      return <span style={{
        backgroundColor: '#FFF4F2', 
        paddingRight: 10, 
        paddingLeft: 10, 
        paddingTop: 6, 
        paddingBottom: 6, 
        border: '1px solid #CB3A31', 
        color: '#CB3A31', 
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>High</span>
    } else if (data == "2") {
      return <span style={{
        backgroundColor: '#FFF4EB', 
        paddingRight: 10, 
        paddingLeft: 10, 
        paddingTop: 6, 
        paddingBottom: 6, 
        border: '1px solid #F37F26', 
        color: '#F37F26', 
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>Medium</span>
    } else {
      return <span style={{
        backgroundColor: '#F0F3FF', 
        paddingRight: 10, 
        paddingLeft: 10, 
        paddingTop: 6, 
        paddingBottom: 6, 
        border: '1px solid #3267E3', 
        color: '#3267E3', 
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>Low</span>
    }
  }

  const renderStatusFileHistories = (status, statusName) => {
    if (status == "CREATED" || status == "REVISE") {
      return <div style={{background: '#FFF4EB', border: '1px solid #F37F26',  borderRadius: '6px'}} className="px-2 py-1 font-weight-bold  mt-3 text-mirae w-50 text-center">{statusName}</div>
    } else if(status == 'APPROVE' || status == 'APPROVE_REVISED_VERSION') {
       return <div style={{color: '#20573D', background: '#F7F7F7', border: '1px solid #B8DBCA',  borderRadius: '6px'}} className="px-2 py-1 font-weight-bold  mt-3 w-50 text-center">{statusName}</div>
    } else {
      return <div style={{color: '#CB3A31', background: '#FFF4F2', border: '1px solid #EEB4B0', borderRadius: '6px'}} className="px-2 py-1  font-weight-bold mt-3 w-50 text-center">{statusName}</div>
    }
}


  const renderBatasWaktu = (data) => {
    if(!data) return '-'

    return moment(data).format('DD-MMM-YY | HH:mm') + " WIB"
  }



  const getListDocumentHistoriDetail = (id) => {
    axios.get(`/services/documentservice/api/file-histories/${id}`).then(res => {
      setListDocumentHistoriDetail(res.data.data.content)
    }).catch(err => {

    })
  }

 


 
  return (


    <div className="px-5 pt-4" style={{height: '100%'}}>

      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">History File Detail</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">List Documents History</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid">





        <div className="row">
          {listDocumentHistoriDetail ? listDocumentHistoriDetail?.map((item, index) => {
            return   <div className="col-lg-12">
            <div className="card" style={{borderRadius: '10px'}}>
              <div style={{background: '#FFF4EB', borderTopRightRadius: '10px', borderTopLeftRadius: '10px'}} className="px-3 py-3">
                <span className='mr-2'>{item?.fileNumber}</span>{'  '} • {'  '}<span className='mr-2 ml-2'>{item?.senderBranchName}</span>{'  '} • {'  '}<span className='mr-2 ml-2'>{item?.createdDateFormat}</span>
              </div>
              <div className="card-body">
                
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-4 my-2">
                   <h4 className='font-weight-bold'>{item.regarding ? item.regarding : "-"}</h4>
                  </div>
                  <div className="col-12 col-md-2 col-lg-2 my-2">
                   <h5 className='font-weight-bold'>Status</h5>
                    {renderStatusFileHistories(item?.status, item?.statusName)}
                  </div>
                  <div className="col-12 col-md-3 col-lg-3 my-2">
                   <h5 className='font-weight-bold'>Last Approval</h5>
                   <h5 className='font-italic font-weight-light mt-3'>{item?.name}</h5>
                  </div>
                  <div className="col-12 col-md-3 col-lg-3 my-2">
                   <h5 className='font-weight-bold'>Date</h5>
                   <h5 className='font-italic font-weight-light mt-3'>{item?.timestampFormat}</h5>
                  </div>
                </div>
              </div>
            </div>           
          </div>
          }) : <></>}
        </div>

     

      </div>

      
     
    </div >
    
    
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar
});


const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(HistoriDetail);
