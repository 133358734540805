import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import '../announcement/announcement.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]

import parse from 'html-react-parser';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'

import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { Editor } from '@tinymce/tinymce-react';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import DragUploadIcon from 'app/component/upload-drag-icon';
import CloseDeleteIcon from 'app/component/close-delete-icon';
import { MultiSelect } from "react-multi-select-component"
import { Accordion, Carousel, Form } from 'react-bootstrap';
import { TextField } from '@mui/material';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import CustomReactPanZoom from 'app/component/custom-react-pan-zoom';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
  match?: any
}


export const VerificationDetail = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)
 
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')
  const [limit, setLimit] = useState<any>(5)
  const [listAnnouncement, setListAnnouncement] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [selectedItem, setItemSelected] = useState<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<any>(false)
  const [date, setDate] = useState<any>(''); 
  const [listTo, setListTo] = useState<any>([])
  const [postDate, setPostDate] = useState<any>('');
  const [to, setTo] = useState<any>([])
  const [fileContent, setFileContent] = useState<any>('')
  const editorRef = useRef(null);
  const [files, setFiles] = React.useState(null);
  const [urlImage, setUrlImage] = useState<any>(null)
  const [title, setTile] = useState<any>('')
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false)
  const [datePost, setDatePost] = useState<any>('')
  const [dateCreated, setDateCreated] = useState<any>('')
  const [id, setId] = useState<any>("0")
  const [pengirim, setPengirim] = useState<any>("")
  const [urlProfileImage, setUrlProfileImage] = useState<any>("")
  const [index, setIndex] = useState(0);
  const [showModalApprove, setShowModalApprove] = useState<any>(false)
  const [showModalReject, setShowModalReject] = useState<any>(false)
  const [showModalCompare, setShowModalCompare] = useState<any>(false)
  const [showModalPreviewImage, setShowModalPreviewImage] = useState<any>(false)
  const [showImagePreviewId, setShowImagePreviewId] = useState<any>(0)
  const [detail, setDetail] = useState<any>([])

  
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const onDropHandler = (ev) => {
    ev.preventDefault();


    console.log(ev)
    let file = null;
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file =
        [...ev.dataTransfer.items]
          .find((item: any) => item.kind === "file")
          .getAsFile() ;
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }

    console.log(file)
    setFiles(file);
    setUrlImage(URL.createObjectURL(file))
  };

  const onDragOver = (ev) => ev.preventDefault();

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    if(props.location.state?.search == '') {
      setListSearch([])
      setDefault()
    } else {
      getSearch(props.location.state?.search)
    }
  
  }, [props.location.state?.search, sortType, sortPriority, sortLastModified, sortDate, page, size, totalPage]);



  const getDetailAnnouncement = (id) => {
    let data = []
    axios.get(`https://dev-neohotsgateway.miraeasset.io/services/hotsregistration/api/preregis/customer/detail/${id}`).then((res) => {
      console.log('detail nasabah', res.data.data)
      
      setDetail(res.data.data)
      // setTile(res.data.data.title)
      // setDateCreated(res.data.data.createdDateFormat)
      // setPostDate(res.data.data.postedDateFormat)
        // res.data.data.to.map((dataarr, i) => {
        //   data.push({label: dataarr.branchName, value: dataarr.branchCode})
        // })
        // setPengirim(`${res.data.data.senderEmployeeName} - ${res.data.data.senderBranchName}`)
        // setFileContent(res.data.data.fileContent)
      
        // if(res.data.data.image) {
        //   setUrlImage('data:image/png;base64,'+ res.data.data.image)
        // } else {
        //   setUrlImage("")
        // }
       
        // setUrlProfileImage(`data:image/png;base64 , ${res.data.data.senderProfilePicture}`)
        // MarkAsREad(res.data.data)
      console.log(data)
      // setTo(data)
      // console.log(to)
    }).catch((err) => {
      console.log(err)
    })
  }

  const MarkAsREad = (data) => {
    
    axios.post(`services/documentservice/api/notification-user/mark-has-read?notificationId=${data.notificationId}`).then((res) => {
      
    }).catch((err) => {
      
    })
  }




  useEffect(() => {
    console.log('data search', props.location.state?.search)
    console.log(props.match.params.id)
    // alert(props.location.state.search)
    getSearch(props.location.state?.search)
   
    
    getUserList()
    getDepartmentList()

    getDetailAnnouncement(props.match.params.id)
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc')
    setSortPriority('priority.keyword,asc')
    setSortLastModified('lastApprovedByName.keyword,asc')
    setSortDate('date,asc')
  }

  const getListAnnouncement = (page, limit) => {
    axios.get(`services/documentservice/api/announcement/dashboard?page=${page}&size=${limit}`).then(res => {
      console.log(res)
      setListAnnouncement(res.data.data?.content)
      setTotalPage(res.data.data?.totalPages)
    })
  }


  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

  
  const getSearch = async (e = '') => {
    if(e.length > 1 ) {
      setLoadingList(true)
      axios.get(`services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`).then( async (result) => {
        console.log(result.data)
        setListSearch(result.data.content)
        setTotalPage(result.data?.totalPages)
        await delayTime(2000)
        setLoadingList(false)
      }).catch(err => {
        setListSearch([])
        setLoadingList(false)
      })
    }
  }



  

  const getUserList = () => {
    axios.get('services/uaadocservice/api/users/get-list-user-not-in-employee-no').then(res => {
      console.log('user', res)
    }).catch(err => {
      console.log(err)
    })
  }

  const getDepartmentList = () => {
    axios.get('services/uaadocservice/api/department/list').then(res => {
      console.log('dept', res)
      if(res.data.length > 1) {
        res.data.map((data, i) => {
          // setListTo([...listTo, {label: data.deptName, value: data.deptCode}])
          setListTo(oldArray => [...oldArray, {label: data.deptName, value: data.deptCode}]);
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }


  const openModalDelete = (item) => {
    setShowModal(true)
    setItemSelected(item)
  }

  const deleteFile = (id) => {
    setLoadingDelete(true)
    axios.delete(`services/documentservice/api/announcement/${id}`).then(res => {
      setLoadingDelete(false)
      toast.success('Success Delete Annoucement', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setShowModal(false)
        setItemSelected(null)
        setPage(1)
        setLimit(5)
        getListAnnouncement(1, 5)
    }).catch(err =>{
      setLoadingDelete(false)
      toast.error('Failed Delete Announcement', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    })
  }

  const handlerChangeImage = (data) => {
      console.log(data)
      setFiles(data)
      setUrlImage(URL.createObjectURL(data))
  }

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';
    event.currentTarget.onerror = null;
  };

  const handleClose = () => {
    setShowModalApprove(false)
    setShowModalCompare(false)
    setShowModalReject(false)
    setShowModalPreviewImage(false)
  };
 
  return (
    <div className="px-5 pt-4" style={{background: '#fff', height: '100%'}}>
      <button className="btn px-0" onClick={() => history.goBack()}>
      <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
      </button>
      <div className="row mt-4">
        <div className="col-6">
        <h1 className="mb-0 font-size-32">Waiting Verification</h1>
        </div>
        <div className="col-6 text-end">
          
            {/* <h1 className="mb-0 font-size-32">{title}</h1> */}
            
            <a className='btn btn-primary text-white text-end mr-2'  onClick={(data) => setShowModalCompare(true)}>Compare Data KTP</a>  
            <a className='btn btn-danger text-white text-end mr-2'  onClick={(data) => setShowModalReject(true)}>Reject</a>
            <a className='btn btn-success text-white text-end mr-4'  onClick={(data) => setShowModalApprove(true)}>Approve</a>
          
          {/* <div className="d-flex align-items-center justify-content-between">
          <div className="" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
            
            <img className="rounded-circle header-profile-user-announcement text-center"  onError={(e) => imageOnErrorHandler(e)} src={urlProfileImage ? urlProfileImage : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
                        alt="Header Avatar" />
              <div className='mx-2'>
              <p className="text-center mb-0">
                {pengirim}
                </p>
              <p className='mb-0'>
                {postDate}
              </p>
              </div>
            </div>
          </div> */}
        </div>
        <div className="row mt-3">
          <p className='' style={{fontSize:'18px',}}> M ADIB YUSRUL MUNA</p>
        </div>
        <div className="row mt-3">
          <div className="col-12">
        <Accordion defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0">
            <Accordion.Header><h5>Data Nasabah</h5></Accordion.Header>
            <Accordion.Body style={{background:'#fff'}}>
            <div className="row align-items-center  mb-3">
                <div className="col-3">
                  User ID
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={detail?.userId}/>
                </div>
                <div className="col-3">
                  Nomor Identitas
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'3319023004960003'}/>
                </div>
              </div>
              <div className="row align-items-center  mb-3">
                <div className="col-3">
                  Nama Lengkap (Sesuai KTP)
                </div>
                <div className="col-3">
                  {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={detail?.name}/> */}
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'M ADIB YUSRUL MUNA'}/>
                </div>
                <div className="col-3">
                  NPWP
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'855739629506000'}/>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-3">
                Tempat & Tanggal Lahir
                </div>
                <div className="col-2">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'KUDUS'}/>
                </div>
                <div className="col-1">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'30/04/1996'}/>
                </div>
                <div className="col-3">
                  Negara tempat lahir
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={detail?.countryOfBirth}/>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-3">
                Email
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'work.adib@gmail.com'}/>
                </div>
                <div className="col-3">
                Nama Gadis Ibu Kandung
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'TUTI'}/>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-3">
                Agama
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={detail?.religionName}/>
                </div>
                <div className="col-3">
                Jenis Kelamin
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'LAKI - LAKI'}/>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-3">
                Status Pernikahan
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'BELUM MENIKAH'}/>
                </div>
                <div className="col-3">
                Status Rumah
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={detail?.ownershipOfHouseStatusName}/>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h5>Alamat</h5>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-3">
                  Alamat Sesuai KTP
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={detail?.address}/>
                </div>
                <div className="col-3">
                Kelurahan
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={detail?.province}/>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-3">
                  Alamat domisili
                </div>
                <div className="col-3">
                  {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={detail?.domicileAddress}/> */}
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'JL KELAPA SAWIT III NO 11A, RT 008/019'}/>
                </div>
                <div className="col-3">
                Kab/Kota
                </div>
                <div className="col-3">
                  {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={detail?.city}/> */}
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-3">
                  Kode Pos
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'24656'}/>
                </div>
                
              </div>

              {/* <div className="row">
                <div className="col-12">
                  <h5>Data SLIK</h5>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-3">
                Nomor Identitas Pasangan
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'1310003403040'}/>
                </div>
                <div className="col-3">
                Tanggal Lahir Pasangan
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'05 September 1990'}/>
                </div>
              </div> */}

              
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
            <Accordion.Header><h5>Data Bank</h5></Accordion.Header>
            <Accordion.Body className='' style={{background:'#fff'}}>
              <div className="row align-items-center mb-3">
                <div className="col-3">
                Bank RDN
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={detail?.bankRDNName}/>
                </div>
                <div className="col-3">
                No Rekening
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'1280544147'}/>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h5>Rekening Pencairan</h5>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-3">
                Bank
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded   w-100' type="text" disabled={true} value={'BANK MANDIRI'}/>
                </div>
                <div className="col-3">
                No Rekening
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded   w-100' type="text" disabled={true} value={'1230007731252'}/>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-3">
                Nama Pemilik Rekening
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded   w-100' type="text" disabled={true} value={'M ADIB YUSRUL MUNA'}/>
                </div>
                {/* <div className="col-3">
                Cabang
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded   w-100' type="text" disabled={true} value={'KCP Galaxy'}/>
                </div> */}
              </div>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
            <Accordion.Header><h5>Data Pekerjaan</h5></Accordion.Header>
            <Accordion.Body style={{background:'#fff'}}>
            <div className="row align-items-center mb-3">
                <div className="col-3">
                Pekerjaan
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded   w-100' type="text" disabled={true} value={detail?.occupation}/>
                </div>
                <div className="col-3">
                Nama Perusahaan / Tempat Bekerja
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded   w-100' type="text" disabled={true} value={detail?.companyName}/>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-3">
                Bidang Usaha
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded   w-100' type="text" disabled={true} value={detail?.companyType}/>
                </div>
                <div className="col-3">
                Jabatan
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded   w-100' type="text" disabled={true} value={detail?.position}/>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h5>Alamat Perusahaan Tempat Bekerja</h5>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-3">
                Alamat
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded   w-100' type="text" disabled={true} value={'Jln.KH Noer Ali'}/>
                </div>
                <div className="col-3">
                Kab/Kota
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded   w-100' type="text" disabled={true} value={'Jakarta'}/>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h5>Pendapatan</h5>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-3">
                Pendapatan Kotor Per Tahun
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'Rp 180,000,000'}/>
                </div>
                
              </div>

              <div className="row">
                <div className="col-12">
                  <h5>Sumber Dana & Tujuan Investasi</h5>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-3">
                Sumber Dana
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'Gaji'}/>
                </div>
                <div className="col-3">
                Tujuan Investasi
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'Investasi Jangka Panjang'}/>
                </div>
              </div>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
            <Accordion.Header>Dokumen</Accordion.Header>
            <Accordion.Body style={{background: '#fff'}}>
            <div className="row align-items-center mb-3">
                <div className="col-6">
                KTP
                </div>
                <div className="col-6">
                NPWP
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-6">
                  <img className='image-button w-50' src="content/images/ktp-1.jpeg" alt="" onClick={(data) => {
                    setShowModalPreviewImage(true)
                    handleSelect(0,null)
                    }}/>

                </div>
                <div className="col-6">
                <img className='image-button w-50' src="content/images/npwp-1.jpeg" alt="" onClick={(data) => {
                    setShowModalPreviewImage(true)
                    handleSelect(1, null)
                    }}/>    
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-6">
                Selfie
                </div>
                <div className="col-6">
                Tanda Tangan
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-6">
                  <img className='image-button w-50' src="content/images/selfie-1.jpeg" alt="" onClick={(data) => {
                    setShowModalPreviewImage(true)
                    handleSelect(2, null)
                    }}/>                   
                </div>
                <div className="col-6">
                  <img className='image-button w-50' src="content/images/ttd-1.jpeg" alt="" onClick={(data) => {
                      setShowModalPreviewImage(true)
                      handleSelect(3, null)
                      }}/>    
                  </div>
              </div>
                
            </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="">
            <Accordion.Header>Approval History</Accordion.Header>
            <Accordion.Body style={{background: '#fff'}}>
            <div className="row align-items-center mb-3">
                  <table className="table table-centered table-nowrap mb-0 table-responsive">
                    <thead className="" style={{background: '#fff', borderTopLeftRadius: 30, borderTopRightRadius: 30}}>
                      <tr>
                        <th className='py-4 px-4'>No</th>
                        <th className='py-4 px-4'>Create Date</th>
                        <th className="px-4">UID Nasabah</th>
                        <th className="px-4">Status</th>
                        <th className="px-4">Approval</th>
                        <th className="px-4">Revision</th>
                      </tr>
                    </thead>
                    <tbody className="" style={{background: '#fff'}}>
                      
                            <tr className="" style= {{background: '#FFF4EB', cursor: 'pointer'}}>
                              <td className="" >
                              1
                              </td>
                              <td className="" style={{wordBreak: 'break-all', minWidth: '200px', maxWidth: '250px', whiteSpace: 'pre-wrap'}}  >
                              {/* {item?.postedDateFormat} */}
                              05/08/22 11:14
                              </td>
                              <td className="" style={{wordBreak: 'break-all', minWidth: '300px', maxWidth: '500px', whiteSpace: 'pre-wrap'}}  > 
                              {/* {item?.title} */}
                              username123
                              </td>
                              <td className="" style={{wordBreak: 'break-all', minWidth: '300px', maxWidth: '400px', whiteSpace: 'pre-wrap'}}  >  
                              Waiting Approval KYC
                              </td>
                              <td className="" style={{wordBreak: 'break-all', minWidth: '200px', maxWidth: '300px', whiteSpace: 'pre-wrap'}}   > 
                              Customer
                              </td>
                              <td className="" style={{wordBreak: 'break-all', minWidth: '350px', maxWidth: '500px', whiteSpace: 'pre-wrap'}}  >  
                              Tempat tanggal lahir pada registrasi online tidak sesuai/berbeda dengan lampiran KTP 
                              </td>
                            
                            
                            </tr>
                        
                    </tbody>
                  </table>
                
            </div>
                
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
          </div>
        </div>
    </div>
      {/* <div className="bg-white shadow-md my-4" style={{borderRadius: '16px'}}>
        <div>
        
        {
          urlImage ? <img className="mx-auto d-block bg-white" style={{width: '100%', height: '500px', borderTopRightRadius: '16px', borderTopLeftRadius: '16px'}} src={urlImage}
          alt="Sign" onError={(e) => imageOnErrorHandler(e)} /> : <></>
        }
        </div>
        <div className="px-3 pt-4 pb-5">
          {fileContent ? parse(fileContent): <></>}
        </div>
      </div> */}
     
      {/* Modal */}
      <Modal isOpen={showModal} toggle={handleClose} size="lg" centered onClosed={() => {
              setShowModalApprove(false)
              setItemSelected(null)
            }}>
              <ModalHeader toggle={handleClose} style={{borderBottom: 'none'}}>
              </ModalHeader>
              <ModalBody id="documenttestApp.document.delete.question">
                  <h3 className="text-center">
                    Are you sure want to delete <span className="font-weight-bold">"{selectedItem?.title}"</span> announcement ?
                  </h3>
                  <div className="text-center mt-5">
                    <button className="btn btn-success mx-2 px-4" onClick={() => {
                      // deleteFile(selectedItem?.id)
                      console.log('Button Yes Approve')
                      }
                    } 
                      disabled={loadingDelete}>
                      {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' />  : <></>}   Yes
                    </button >
                    <button disabled={loadingDelete} className="btn btn-secondary mx-2" onClick={() => {
                      setShowModalApprove(false);
                      setItemSelected(null)
                    }}>
                      Cancel
                    </button >
                  </div>
              </ModalBody>
              <ModalFooter style={{borderTop: 'none'}}>

              </ModalFooter>
            </Modal>

    <Modal isOpen={showModalCompare} toggle={handleClose} size="xl" style={{marginTop: '5%'}} onClosed={() => {
              setShowModalCompare(false)
              setItemSelected(null)
            }}>
        <div className="my-5">
        <div className="px-5 pt-4" style={{height: '100%'}}>
          <div className="row">
            <div className="col-12">
              <div className="row page-title-box">
                <div className="col-11">
                  <h1 className="mb-0 font-size-28">Compare KTP</h1>
                </div>
                <div className="col-1 text-right">
                <a className="mx-2" onClick={() => {
                    setShowModalCompare(false);
                    setItemSelected(null)
                  }}>
                    <img src="content/icon/ic_close.png" alt="" width={20} height={20} style={{background: '#FFF', cursor: 'pointer'}} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-5">
              <img className='w-100' src="content/images/ktp-1.jpeg" alt="" />
            </div>
            <div className="col-7"> 
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                Nomor Identitas
                </div>
                <div className="col-8">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'3275022009940009'}/>
                </div>
              </div> 
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                Nama Lengkap (Sesuai KTP)
                </div>
                <div className="col-8">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'M ADIB YUSRUL MUNA'}/>
                </div>
              </div> 
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                Tempat / Tgl Lahir
                </div>
                <div className="col-8">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'KUDUS, 30 April 1996'}/>
                </div>
              </div> 
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                Jenis Kelamin
                </div>
                <div className="col-8">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'LAKI - LAKI'}/>
                </div>
              </div> 
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                Alamat
                </div>
                <div className="col-8">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'KRAMAT KECIL NO 211'}/>
                </div>
              </div> 
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                RT/RW
                </div>
                <div className="col-8">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'001/003'}/>
                </div>
              </div> 
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                Kel/Desa
                </div>
                <div className="col-8">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'KUTESANGE'}/>
                </div>
              </div> 
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                Kecamatan
                </div>
                <div className="col-8">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'TERANGUN (TERANGON)'}/>
                </div>
              </div> 
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                Agama
                </div>
                <div className="col-8">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'Islam'}/>
                </div>
              </div> 
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                Status Perkawinan
                </div>
                <div className="col-8">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'Belum Kawin'}/>
                </div>
              </div> 
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                Pekerjaan
                </div>
                <div className="col-8">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'Pelajar/Mahasiswa'}/>
                </div>
              </div> 
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                Kewarganegaraan
                </div>
                <div className="col-8">
                  <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'WNI'}/>
                </div>
              </div> 
            </div>
          </div>          
        </div>
      </div>
    </Modal>

    <Modal isOpen={showModalReject} toggle={handleClose} size="xl" style={{marginTop: '5%'}} onClosed={() => {
              setShowModalReject(false)
              setItemSelected(null)
            }}>
        <div className="my-3">
        <div className="px-5 pt-4" style={{height: '100%'}}>
          <div className="row">
            <div className="col-12">
              <div className="row page-title-box">
                <div className="col-11">
                  <h1 className="mb-2 font-size-28">Reject Reason</h1>
                </div>
                <div className="col-1 text-right">
                <a className="mx-2" onClick={() => {
                    setShowModalReject(false)
                    setItemSelected(null)
                  }}>
                    <img src="content/icon/ic_close.png" alt="" width={20} height={20} style={{background: '#FFF', cursor: 'pointer'}} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-1">
            <Form>
              <div key={'default-checkbox'} className="">
                <Form.Check 
                    type='checkbox'
                    id={`default-checkbox`}
                    label={''}
                    className='font-size-16 font-weight-light text-right'
                  />
                </div>
            </Form>
            </div>
            <div className="col-5">
              <Accordion defaultActiveKey={['0']}  alwaysOpen className=''>
                  <Accordion.Item eventKey="1" className=''>
                      <Accordion.Header className='mb-1' style={{background:'#fff'}}>Nama</Accordion.Header>
                      <Accordion.Body className='' style={{background:'#f5f5f5'}}>
                        <div className="row align-items-center mb-3">
                          <div className="col-12">
                          Nama pada registrasi online tidak sesuai/berbeda dengan lampiran KTP 
                          </div>
                        </div>
                      </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-1">
            <Form>
              <div key={'default-checkbox'} className="">
                <Form.Check 
                    type='checkbox'
                    id={`default-checkbox`}
                    label={''}
                    className='font-size-16 font-weight-light text-right'
                  />
                </div>
            </Form>
            </div>
            <div className="col-5">
              <Accordion defaultActiveKey={['0']}  alwaysOpen className=''>
                  <Accordion.Item eventKey="1" className=''>
                      <Accordion.Header className='mb-1' style={{background:'#fff'}}>Nama Kantor</Accordion.Header>
                      <Accordion.Body className='' style={{background:'#f5f5f5'}}>
                        <div className="row align-items-center mb-3">
                          <div className="col-12">
                          Nama pada registrasi online tidak sesuai/berbeda dengan lampiran KTP 
                          </div>
                        </div>
                      </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-1">
            <Form>
              <div key={'default-checkbox'} className="">
                <Form.Check 
                    type='checkbox'
                    id={`default-checkbox`}
                    label={''}
                    className='font-size-16 font-weight-light text-right'
                  />
                </div>
            </Form>
            </div>
            <div className="col-5">
              <Accordion defaultActiveKey={['0']}  alwaysOpen className=''>
                  <Accordion.Item eventKey="1" className=''>
                      <Accordion.Header className='mb-1' style={{background:'#fff'}}>Tempat Tanggal Lahir</Accordion.Header>
                      <Accordion.Body className='' style={{background:'#f5f5f5'}}>
                        <div className="row align-items-center mb-3">
                          <div className="col-12">
                          Tempat tanggal lahir pada registrasi online tidak sesuai/berbeda dengan lampiran KTP 
                          </div>
                        </div>
                      </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-1">
            <Form>
              <div key={'default-checkbox'} className="">
                <Form.Check 
                    type='checkbox'
                    id={`default-checkbox`}
                    label={''}
                    className='font-size-16 font-weight-light text-right'
                  />
                </div>
            </Form>
            </div>
            <div className="col-5">
              <Accordion defaultActiveKey={['0']}  alwaysOpen className=''>
                  <Accordion.Item eventKey="1" className=''>
                      <Accordion.Header className='mb-1' style={{background:'#fff'}}>Foto KTP</Accordion.Header>
                      <Accordion.Body className='' style={{background:'#f5f5f5'}}>
                        <div className="row align-items-center mb-3">
                          <div className="col-12">
                          Foto KTP pada registrasi online tidak sesuai/berbeda dengan lampiran KTP 
                          </div>
                        </div>
                      </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-1">
            <Form>
              <div key={'default-checkbox'} className="">
                <Form.Check 
                    type='checkbox'
                    id={`default-checkbox`}
                    label={''}
                    className='font-size-16 font-weight-light text-right'
                  />
                </div>
            </Form>
            </div>
            <div className="col-5">
              <Accordion defaultActiveKey={['0']}  alwaysOpen className=''>
                  <Accordion.Item eventKey="1" className=''>
                      <Accordion.Header className='mb-1' style={{background:'#fff'}}>Kota Sesuai KTP</Accordion.Header>
                      <Accordion.Body className='' style={{background:'#f5f5f5'}}>
                        <div className="row align-items-center mb-3">
                          <div className="col-12">
                          Kota pada registrasi online tidak sesuai/berbeda dengan lampiran KTP 
                          </div>
                        </div>
                      </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-1">
            <Form>
              <div key={'default-checkbox'} className="">
                <Form.Check 
                    type='checkbox'
                    id={`default-checkbox`}
                    label={''}
                    className='font-size-16 font-weight-light text-right'
                  />
                </div>
            </Form>
            </div>
            <div className="col-5">
              <Accordion defaultActiveKey={['0']}  alwaysOpen className=''>
                  <Accordion.Item eventKey="1" className=''>
                      <Accordion.Header className='mb-1' style={{background:'#fff'}}>Foto Selfie</Accordion.Header>
                      <Accordion.Body className='' style={{background:'#f5f5f5'}}>
                        <div className="row align-items-center mb-3">
                          <div className="col-12">
                          Lampiran foto selfie dengan KTP tidak sesuai/terpotong/terhapus/tidak jelas dan blur 
                          </div>
                        </div>
                      </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-1">
            <Form>
              <div key={'default-checkbox'} className="">
                <Form.Check 
                    type='checkbox'
                    id={`default-checkbox`}
                    label={''}
                    className='font-size-16 font-weight-light text-right'
                  />
                </div>
            </Form>
            </div>
            <div className="col-5">
              <Accordion defaultActiveKey={['0']}  alwaysOpen className=''>
                  <Accordion.Item eventKey="1" className=''>
                      <Accordion.Header className='mb-1' style={{background:'#fff'}}>Provinsi Sesuai KTP</Accordion.Header>
                      <Accordion.Body className='' style={{background:'#f5f5f5'}}>
                        <div className="row align-items-center mb-3">
                          <div className="col-12">
                          Provinsi pada registrasi online tidak sesuai/berbeda dengan lampiran KTP 
                          </div>
                        </div>
                      </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-1">
            <Form>
              <div key={'default-checkbox'} className="">
                <Form.Check 
                    type='checkbox'
                    id={`default-checkbox`}
                    label={''}
                    className='font-size-16 font-weight-light text-right'
                  />
                </div>
            </Form>
            </div>
            <div className="col-5">
              <Accordion defaultActiveKey={['0']}  alwaysOpen className=''>
                  <Accordion.Item eventKey="1" className=''>
                      <Accordion.Header className='mb-1' style={{background:'#fff'}}>Tanda Tangan</Accordion.Header>
                      <Accordion.Body className='' style={{background:'#f5f5f5'}}>
                        <div className="row align-items-center mb-3">
                          <div className="col-12">
                          Tanda tangan pada registrasi online tidak sesuai/berbeda dengan lampiran KTP 
                          </div>
                        </div>
                      </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-1">
            <Form>
              <div key={'default-checkbox'} className="">
                <Form.Check 
                    type='checkbox'
                    id={`default-checkbox`}
                    label={''}
                    className='font-size-16 font-weight-light text-right'
                  />
                </div>
            </Form>
            </div>
            <div className="col-5">
              <Accordion defaultActiveKey={['0']}  alwaysOpen className=''>
                  <Accordion.Item eventKey="1" className=''>
                      <Accordion.Header className='mb-1' style={{background:'#fff'}}>No. Telp Kantor</Accordion.Header>
                      <Accordion.Body className='' style={{background:'#f5f5f5'}}>
                        <div className="row align-items-center mb-3">
                          <div className="col-12">
                          No. telp kantor pada registrasi online tidak sesuai/berbeda dengan lampiran KTP 
                          </div>
                        </div>
                      </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-1">
            <Form>
              <div key={'default-checkbox'} className="">
                <Form.Check 
                    type='checkbox'
                    id={`default-checkbox`}
                    label={''}
                    className='font-size-16 font-weight-light text-right'
                  />
                </div>
            </Form>
            </div>
            <div className="col-5">
              <Accordion defaultActiveKey={['0']}  alwaysOpen className=''>
                  <Accordion.Item eventKey="1" className='accordion-button-custom'>
                      <Accordion.Header className='mb-1' style={{background:'#fff'}}>Foto NPWP</Accordion.Header>
                      <Accordion.Body className='' style={{background:'#f5f5f5'}}>
                        <div className="row align-items-center mb-3">
                          <div className="col-12">
                          Lampiran NPWP tidak sesuai/tidak dicantumkan/nama & nomor NPWP berbeda
                          </div>
                        </div>
                      </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
            </div>
          </div>
            <div className="text-end mt-5">
              <button className="btn btn-primary mx-2 px-4" onClick={() => {
                console.log('Data Approve Function');
                setShowModalReject(false);
                setItemSelected(null)
              }} disabled={loadingDelete}>
                {loadingDelete ? <Spinner size="sm" className='mr-1' />  : <></>}   Cancel
              </button >
              <button disabled={loadingDelete} className="btn btn-danger mx-2" onClick={() => {
                setShowModalReject(false);
                setItemSelected(null)
              }}>
                          Confirm Reject
              </button >
              </div>
            </div>
            </div>
        <ModalFooter style={{borderTop: 'none'}}>

        </ModalFooter>
      </Modal>

    <Modal isOpen={showModalApprove} toggle={handleClose} size="lg" centered onClosed={() => {
        setShowModalApprove(false)
        setItemSelected(null)
      }}>
        <ModalHeader toggle={handleClose} style={{borderBottom: 'none'}}>
        </ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
            <h3 className="text-center">
              Are you sure want to approve <span className="font-weight-bold">"Makanna Curtis{selectedItem?.title}"</span> data ?
            </h3>
            <div className="text-center mt-5">
              <button className="btn btn-success mx-2 px-4" onClick={() => {
                console.log('Data Approve Function');
                setShowModalApprove(false);
                setItemSelected(null)
              }} disabled={loadingDelete}>
                {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' />  : <></>}   Yes
              </button >
              <button disabled={loadingDelete} className="btn btn-secondary mx-2" onClick={() => {
                setShowModalApprove(false);
                setItemSelected(null)
              }}>
                          Cancel
              </button >
            </div>
        </ModalBody>
        <ModalFooter style={{borderTop: 'none'}}>

        </ModalFooter>
      </Modal>

      <Modal isOpen={showModalPreviewImage} toggle={handleClose} size="xl" centered onClosed={() => {
        setShowModalPreviewImage(false)
        setItemSelected(null)
      }}>
        <ModalHeader toggle={handleClose} style={{borderBottom: 'none'}}>
        </ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
        <Carousel variant="dark" activeIndex={index} onSelect={handleSelect} data-bs-interval="false" interval={null}>
          <Carousel.Item>
            <CustomReactPanZoom
              image="content/images/ktp-1.jpeg"
              alt="Image alt text"
            />
          </Carousel.Item>
          <Carousel.Item>
            <CustomReactPanZoom
              image="content/images/npwp-1.jpeg"
              alt="Image alt text"
            />
          </Carousel.Item>
          <Carousel.Item>
            <CustomReactPanZoom
              image="content/images/selfie-1.jpeg"
              alt="Image alt text"
            />
          </Carousel.Item>
          <Carousel.Item>
            <CustomReactPanZoom
              image="content/images/ttd-1.jpeg"
              alt="Image alt text"
            />
          </Carousel.Item>
        </Carousel>
            {/* <div className="text-center mt-5">
              <button className="btn btn-success mx-2 px-4" onClick={() => {
                console.log('Data Approve Function');
                setShowModalPreviewImage(false);
                setItemSelected(null)
              }} disabled={loadingDelete}>
                {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' />  : <></>}   Yes
              </button >
              <button disabled={loadingDelete} className="btn btn-secondary mx-2" onClick={() => {
                setShowModalPreviewImage(false);
                setItemSelected(null)
              }}>
                Cancel
              </button >
            </div> */}
        </ModalBody>
        <ModalFooter style={{borderTop: 'none'}}>
        </ModalFooter>
      </Modal>
    

    
    </div>
    
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
 
});


const mapDispatchToProps = {
 
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(VerificationDetail);
