import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import '../announcement/announcement.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import DatePicker from 'react-datepicker';
import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import AccountAnnounceIcon from 'app/component/announ-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';
import Tabs from 'app/component/tabs';
import { Dropdown, DropdownButton } from 'react-bootstrap';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
}

const initialFormData = {
  id : "0",
  type : "",
  priority : "", 
  documentSecurity : "", 
  startDate: new Date(),
  lastDate : new Date(),
  regarding : "",
  fileContent : "", 
  to : [],
  cc : [],
  acknowledge : [], 
  approval : []
}

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}


export const DocumentNew = (props: IDocumentProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [showList, setShowList] = useState(false)
  const [page, setPage] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')
  const [limit, setLimit] = useState<any>(5)
  const [listAnnouncement, setListAnnouncement] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [selectedItem, setItemSelected] = useState<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<any>(false)
  const [isAdmin, setIsAdmin] = useState<Boolean>(false)
  const [isAnnounce, setIsAnnounce] = useState<Boolean>(false)
  const [formData, setFormData] = useState(initialFormData)
  const [searchByDropDown, setSearchByDropDown] = useState<any>('Search by') 
  const [filterAccountType, setfilterAccountType] = useState<any>('Account Type') 
  const [filterBankRdn, setfilterBankRdn] = useState<any>('Bank RDN')

  const [currentTab, setCurrentTab] = useState('1');
  const [currentTabString, setCurrentTabString] = useState('WAITING');

  const [category, setCategory] = useState<any>(0)

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    if(props.location.state?.search == '') {
      setListSearch([])
      setDefault()
    } else {
      getSearch(props.location.state?.search)
    }
  
  }, [props.location.state?.search, sortType, sortPriority, sortLastModified, sortDate, page, size, totalPage]);


  useEffect(() => {
    console.log('data search', props.location.state?.search)
    // alert(props.location.state.search)
    getSearch(props.location.state?.search)
    getListVerification(page, limit, 'WAITING')
    getIsAdmin()
    getIsAnnounce()
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc')
    setSortPriority('priority.keyword,asc')
    setSortLastModified('lastApprovedByName.keyword,asc')
    setSortDate('date,asc')
  }

  const setDropdown = () => {
    
};

const tabs = [
  {
      id: 1,
      tabTitle: 'Waiting Verification',
      title: 'Title 1',
      content: 'Las tabs se generan automáticamente a partir de un array de objetos, el cual tiene las propiedades: id, tabTitle, title y content.'
  },
  {
      id: 2,
      tabTitle: 'Approved',
      title: 'Title 2',
      content: 'Page Approved'
  },
  {
      id: 3,
      tabTitle: 'Rejected',
      title: 'Title 3',
      content: 'Page Rejected'
  }
];

  const getListVerification = (page, limit, status) => {
    switch (status) {
      case '1':
        console.log('WAITING TAB')
        status = 'WAITING'
        break;
      case '2':
        console.log('APPROVED TAB')
        status = 'APPROVED'
        break;
      case '3':
        console.log('REJECTED TAB')
        status = 'REJECTED'
        break;
    
      default:
        console.log('DEFAULT VALUE TAB')
        break;
    }
    axios.get(`https://dev-neohotsgateway.miraeasset.io/services/hotsregistration/api/preregis/customer-filter?page=${page}&size=${limit}&status=${status}`).then(res => {
      console.log(res)
      setListAnnouncement(res.data.data?.content)
      setTotalPage(res.data.data?.totalPages)
      
      
    })
  }


  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

  const getIsAdmin = () => {
    axios.get('/services/uaadocservice/api/is-admin-dms').then(res => {
        console.log('is Admin', res.data)
        setIsAdmin(res.data)
    }).catch(err => {
        setIsAdmin(false)
    })
  }

  const getIsAnnounce = () => {
    axios.get('/services/uaadocservice/api/is-user-may-announce').then(res => {
        console.log('is Admin', res.data)
        setIsAnnounce(res.data)
    }).catch(err => {
        setIsAnnounce(false)
    })
  }
  
  const getSearch = async (e = '') => {
    if(e.length > 1 ) {
      setLoadingList(true)
      axios.get(`services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`).then( async (result) => {
        console.log(result.data)
        setListSearch(result.data.content)
        setTotalPage(result.data?.totalPages)
        await delayTime(2000)
        setLoadingList(false)
      }).catch(err => {
        setListSearch([])
        setLoadingList(false)
      })
    }
  }


  const changeSortType = () => {
    if(sortType == 'typeName.keyword,asc') {
      setSortType('typeName.keyword,desc')
    } else {
      setSortType('typeName.keyword,asc')
    }
  }

  const changeSortPriority = () => {
    if(sortPriority == 'priority.keyword,asc') {
      setSortPriority('priority.keyword,desc')
    } else {
      setSortPriority('priority.keyword,asc')
    }
  }

  const changeSortLastModified = () => {
    if(sortLastModified == 'lastApprovedByName.keyword,asc') {
      setSortLastModified('lastApprovedByName.keyword,desc')
    } else { 
      setSortLastModified('lastApprovedByName.keyword,asc')
    }
  }

  const changeSortDate = () => {
    if(sortDate == 'date,asc') {
      setSortDate('date,desc')
    } else {
      setSortDate('date,asc')
    }
  }

  const goToPage = (id) => {
    setSearch('')
    history.push(`/preview/only/pdf/${id}`)
  }

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log(value)
    setPage(value)
    // getListDocumentHistori(value, limit)
    getListVerification(value, limit, 'APPROVED')
  }

  const changeShow = (e) => {
    // alert(e.target.value)
    let show = e.target.value
    console.log(limit)
    setPage(0)
    setLimit(show)
    getListVerification(1, show, 'APPROVED')
    // getListDocumentHistori(1, show)
  }

  const handleClose = () => {
      setShowModal(false)
  };

  const openModalDelete = (item) => {
    setShowModal(true)
    setItemSelected(item)
  }
 
  const goToDetail = (data) => {
    axios.get(`https://dev-neohotsgateway.miraeasset.io/services/hotsregistration/api/preregis/customer/detail/${data.userId}`).then((res) => {
      console.log('res')
      history.push(`/verification/view/${data.userId}`)
    }).catch((err) => {
      history.push(`/verification/view/${data.userId}`)
    })
  }

  const handleSearchBy=(e)=>{
    console.log('SELECTED', e);
    setSearchByDropDown(e);

  }

  const handleFilterAccountType= (e)=>{
    console.log('SELECTED', e);
    setfilterAccountType(e);
  }

  const handleFilterBankRdn= (e)=>{
    console.log('SELECTED', e);
    setfilterBankRdn(e);
  }

  const changePageTab = (event: React.ChangeEvent<unknown>, value: number) => {
 
    if(value != 0 ) {
     if(category == 0) {
       setPage(value)
       
     } else if (category == 1) {
       setPage(value)
       
     } else if (category == 2) {
       setPage(value)
       
     }
    }
   }

   const changeCategory = (categoryParams) => {
    setCategory(categoryParams)
    if(category == 0) {
      setPage(1)
      setLimit(5)
      props.getDocumentApproval("3103077", page, limit, true);
    } else if (category == 1) {
      setPage(1)
      setLimit(5)
      props.getDocumentAcknowledge("3103077", page, limit, true);
    } else if (category == 2) {
      setPage(1)
      setLimit(5)
      props.getDocumentSuratMasuk("3103077", page, limit, true);
    }
  } 

  const switchTab = (id) =>{
    console.log('ID di switch tab', id)
    switch (id) {
      case '1':
        console.log('WAITING TAB')
        setCurrentTabString('WAITING')
        break;
      case '2':
        console.log('APPROVED TAB')
        setCurrentTabString('APPROVED')
        break;
      case '3':
        console.log('REJECTED TAB')
        setCurrentTabString('REJECTED')
        break;
    
      default:
        console.log('DEFAULT VALUE TAB')
        break;
    }
    console.log('Current Tab String', currentTabString)
    getListVerification(page, limit, currentTabString)
  }

  const handleTabClick = (e) => {
    setListAnnouncement([])
    setCurrentTab(e.target.id);
    console.log(e.target.id);
    getListVerification(page, limit, e.target.id)
}
 
  return (
    <div className="px-5 pt-4" style={{height: '100%', background:'#fff'}}>

    <div className="row">
      <div className="col-12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h1 className="mb-0 font-size-28">Customer Verification</h1>
        </div>
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h1 className="mb-0 font-size-14">New CMS Dashboard</h1>
        </div>
      </div>
    </div>

    {/* {
      isAdmin || isAnnounce ?  <div className="btn btn-primary rounded-lg" onClick={() => goToCreate()}>
      <AccountAnnounceIcon className="mr-1"/> Create Announcement
    </div> : <></>
    } */}
  
  <div className='row mb-4'>
      <div className='col-1'>
        {/* <div className="dropdown d-inline-block"> */}
          {/* <button className="btn btn-light dropdown-toggle waves-effect" data-toggle="dropdown" type="button" id="dropdownMenuButton1" aria-haspopup="true" aria-expanded="false" onMouseOver={() => setDropdown()}>
            Search by
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><a className="dropdown-item" href="#">Something else here</a></li>
          </ul> */}
            <DropdownButton id="dropdown-basic-button" className='' title={searchByDropDown ? searchByDropDown : 'Search by' } onSelect={handleSearchBy} >
              <Dropdown.ItemText>Select Search</Dropdown.ItemText>
              <Dropdown.Divider className='mx-4'></Dropdown.Divider>
              <Dropdown.Item eventKey="NIK">NIK</Dropdown.Item>
              <Dropdown.Item eventKey="YP ID">YP ID</Dropdown.Item>
              <Dropdown.Item eventKey="SID">SID</Dropdown.Item>
              <Dropdown.Item eventKey="Name">Customer Name</Dropdown.Item>
              <Dropdown.Item eventKey="Username">Username</Dropdown.Item>
              <Dropdown.Item eventKey="Email">Email</Dropdown.Item>
              <Dropdown.Item eventKey="User ID">User ID</Dropdown.Item>
              <Dropdown.Item eventKey="Phone">Phone Number</Dropdown.Item>
            </DropdownButton>
          
        {/* </div> */}
      </div>
      <div className="app-search col-9">
          <div className="position-relative">
            <input type="text" className="form-control" placeholder="Enter value here"/>
            <span className="bx bx-search-alt"></span>
            {/* {
              search ?  <div style={{position: 'absolute', top: '20%', right: '0.5%'}} onClick={() => clearSearch()}>
              <ClearSearch />
              </div> : <></>
            } */}
          </div>
        </div>
      <div className='col-2'> 
        <a className='btn btn-primary w-100 text-white'>Search Customer</a>
      </div>      
    </div>

    <div className='row'>
      <div className="col-4">
        <h5>Filter</h5>
      </div>
    </div>
    <div className="row">
      <div className="col-4">
        <div className="page-title-box d-flex align-items-center">
        
        <DropdownButton id="dropdown-basic-button" className='mr-3' title={filterAccountType ? filterAccountType : 'Account Type' } onSelect={handleFilterAccountType}>
            <Dropdown.ItemText>Select Account Type</Dropdown.ItemText>
            <Dropdown.Divider className='mx-4'></Dropdown.Divider>
            <Dropdown.Item eventKey="All">All</Dropdown.Item>
            <Dropdown.Item eventKey="Marjin">Marjin</Dropdown.Item>
            <Dropdown.Item eventKey="Syariah">Syariah</Dropdown.Item>
            <Dropdown.Item eventKey="Reguler">Reguler</Dropdown.Item>
          </DropdownButton>
          <DropdownButton id="dropdown-basic-button" className='mr-3' title={filterBankRdn ? filterBankRdn : 'Bank RDN' } onSelect={handleFilterBankRdn}>
            <Dropdown.ItemText>Select Bank RDN</Dropdown.ItemText>
            <Dropdown.Divider className='mx-4'></Dropdown.Divider>
            <Dropdown.Item eventKey="All">All</Dropdown.Item>
            <Dropdown.Item eventKey="BCA">BCA</Dropdown.Item>
            <Dropdown.Item eventKey="BRI">BRI</Dropdown.Item>
            <Dropdown.Item eventKey="Mandiri">Mandiri</Dropdown.Item>
            <Dropdown.Item eventKey="Sinarmas">Sinarmas</Dropdown.Item>
          </DropdownButton>
        {/* <a className="btn btn-light rounded-lg">Account Type</a> */}
        {/* <a className="btn btn-light">Bank RDN</a> */}
        <a id="dropdown-basic-button" className='btn mr-3'>Reg Date</a>
        <a id="dropdown-basic-button" className='btn mr-3'>Status</a> 
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-lg-12">
        <ul className='nav nav-tabs'>
          {tabs.map((tab, i) =>
            <li className='nav-item'>
              <a className={currentTab == `${tab.id}` ? 'nav-link active' : 'nav-link' } 
                style={{cursor: 'pointer'}} key={i} 
                id={tab.id} 
                disabled={currentTab === `${tab.id}`} 
                onClick={(handleTabClick)}>{tab.tabTitle}
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
              <div className="my-3 bg-light">
                  <table className="table table-centered table-nowrap mb-0 table-responsive table-hover">
                    <thead className="" style={{background: '#f5f5f5', borderTopLeftRadius: 30, borderTopRightRadius: 30}}>
                      <tr>
                        <th className='py-4 px-4' style={{width: ''}}>Reg Date</th>
                        <th className='py-4 px-4'style={{width: '250px'}}>Cust Name</th>
                        <th className='py-4 px-4'style={{width: '250px'}}>Recommender</th>
                        <th className='py-4 px-4'style={{width: '150px'}}>Branch</th>
                        <th className='py-4 px-4 text-center' style={{width: '100px'}}>Bank</th>
                        <th className='py-4 px-4 text-center' style={{width: '100px'}}>Revision</th>
                        { currentTab != '1' && <th className='py-4 px-4' style={{width: '200px'}}>Verification</th>}
                        { currentTab == '1' && <th className='py-4 px-4' style={{width: '200px'}}>Status</th> }
                        
                        { currentTab != '1' && <th className='py-4 px-4' style={{width: '200px'}}>KSEI Status</th>}
                        { currentTab != '1' && <th className='py-4 px-4' style={{width: '200px'}}>RDN Status</th> }
                      </tr>
                    </thead>
                    <tbody className="" style={{background: '#fff'}}>
                      {
                        listAnnouncement.map((item, index) => {
                          return (

                            <tr className="" style= {currentTab == '1' ? {background: '#FFF', cursor: 'pointer'} : {background: '#FFF'}} onClick={() => currentTab == '1' && goToDetail(item)}>
                            {/* // <tr className="" style={item.isRead ? {background: '#FFF', cursor: 'pointer'} : {background: '#FFF4EB', cursor: 'pointer'}}> */}
                              <td className="" style={ currentTab != '1' ? {wordBreak: 'break-all', minWidth: '150px', maxWidth: '200px', whiteSpace: 'pre-wrap'} :{wordBreak: 'break-all', minWidth: '200px', maxWidth: '250px', whiteSpace: 'pre-wrap'} }>
                              {/* {item?.regDate ?? '-'} */}
                              { new Intl.DateTimeFormat('en-US', {year: '2-digit', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', hourCycle: 'h23'}).format(Date.now()) }
                              </td>
                              <td className="" style={currentTab != '1' ? {wordBreak: 'break-all', minWidth: '200px', maxWidth: '300px', whiteSpace: 'pre-wrap'} :{wordBreak: 'break-all', minWidth: '300px', maxWidth: '350px', whiteSpace: 'pre-wrap'} }>
                              {/* {item?.postedDateFormat} */}
                              {/* {item?.custName ?? '-'} */}
                              { index == 0 ? "M ADIB YUSRUL MUNA" : "BRAMA ASHARI"}
                              </td>

                              <td className=""  style={ currentTab != '1' ? {wordBreak: 'break-all', minWidth: '200px', maxWidth: '300px', whiteSpace: 'pre-wrap'} :{wordBreak: 'break-all', minWidth: '300px', maxWidth: '350px', whiteSpace: 'pre-wrap'} }>
                                { item?.recommender == '' ? 'David Cornelius' : item?.recommender}
                              </td>

                              <td className="" style={ currentTab != '1' ? {wordBreak: 'break-all', minWidth: '100px', maxWidth: '300px', whiteSpace: 'pre-wrap'} :{wordBreak: 'break-all', minWidth: '150px', maxWidth: '350px', whiteSpace: 'pre-wrap'} }>
                                {/* { item?.branch ?? '-'}  */}
                              {index % 2 == 1 ? 'Jakarta' : 'Central Park'}
                              </td>

                              <td className="text-center" style={ currentTab != '1' ? {wordBreak: 'break-all', minWidth: '100px', maxWidth: '300px', whiteSpace: 'pre-wrap'} :{wordBreak: 'break-all', minWidth: '200px', maxWidth: '350px', whiteSpace: 'pre-wrap'} }> 
                              {/* { item?.bank ?? '-'}  */}
                              BCA
                              </td>
                              <td className="text-center" >  
                              {/* { item?.revision ?? '-'}  */}
                              {index % 2 == 1 ? '1' : '0'}
                              </td>
                              
                              <td className="" style={ currentTab != '1' ? {wordBreak: 'break-all', minWidth: '200px', maxWidth: '300px', whiteSpace: 'pre-wrap'} :{wordBreak: 'break-all', minWidth: '300px', maxWidth: '350px', whiteSpace: 'pre-wrap'} }>
                                {item?.verification ?? '-'}
                              </td>
                              {currentTab != '1' && 
                              <td style={{wordBreak: 'break-all', minWidth: '100px', maxWidth: '150px', whiteSpace: 'pre-wrap'}}>
                                {item?.kseiStatus ?? '-'}
                              </td> 
                              }
                              { currentTab != '1' && 
                              <td style={{wordBreak: 'break-all', minWidth: '100px', maxWidth: '150px', whiteSpace: 'pre-wrap'}}>
                              {item?.rdnStatus ?? '-'}
                              </td> 
                              }
                            
                            </tr>
                          )
                        })
                      }
                    
                   
                    
                    </tbody>
                  </table>
                </div>


                <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
              <div className="form-group row">

                    <label htmlFor="priority" className="col-sm-1 col-form-label">Show</label>
                    
                    <div className="" style={{width: '80px'}}>
                    <select name="show" value={limit} className="custom-select" onChange={(e) => changeShow(e)}>
                        <option value="2">2</option>
                        <option value="5">5</option>
                        <option value="10" selected>10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                    </select>
                    
                    </div>
                    <label htmlFor="priority" className="col-sm-2 col-form-label">Entries</label>

                    </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6" style={{justifyContent: 'end', display: 'flex'}}>
              
              {/* <Page currentPage={page} totalPage={listDocumentHistori.totalPages} last={listDocumentHistori.last} first={listDocumentHistori.first} changePage={(page) => changePage(page)}/> */}
              <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={changePage}/>

          

              </div>
            </div>

   

            <Modal isOpen={showModal} toggle={handleClose} size="lg" centered onClosed={() => {
              setShowModal(false)
              setItemSelected(null)
            }}>
              <ModalHeader toggle={handleClose} style={{borderBottom: 'none'}}>
              </ModalHeader>
              <ModalBody id="documenttestApp.document.delete.question">
                  <h3 className="text-center">
                    Are you sure want to delete <span className="font-weight-bold">"{selectedItem?.title}"</span> announcement ?
                  </h3>
                  <div className="text-center mt-5">
                    <button className="btn btn-success mx-2 px-4" onClick={() => deleteFile(selectedItem?.id)} disabled={loadingDelete}>
                      {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' />  : <></>}   Yes
                    </button >
                    <button disabled={loadingDelete} className="btn btn-secondary mx-2" onClick={() => {
                      setShowModal(false);
                      setItemSelected(null)
                    }}>
                                Cancel
                    </button >
                  </div>
              </ModalBody>
              <ModalFooter style={{borderTop: 'none'}}>

              </ModalFooter>
            </Modal>
                
    </div>
  );
};


const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar
});


const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentNew);
