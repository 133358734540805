import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, Spinner } from 'reactstrap';
import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
  AvField,
} from 'availity-reactstrap-validation';
import {
  Translate,
  translate,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import Select from 'react-select';

import {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getDocumentDetail,
  getUserList,
  getApprovalList,
  getAcknowledgeList,
  getDeptList,
  getListDocumentCode,
  sendNewDocument,
  saveDocumentToDraft,
} from './document.reducer';
import {
  IDocument,
  IBranchCode,
  IApproval,
} from 'app/shared/model/document.model';
import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import documentPreview from '../preview/document-preview';
import { Editor } from '@tinymce/tinymce-react';

import { MultiSelect } from 'react-multi-select-component';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import ArrowRightIconWhite from 'app/component/arrow-right-icon-white';
import SignatureIcon from 'app/component/signature-icon';
import SignatureWhiteCreateFile from 'app/component/signature-white-create-file';
import SaveDraftIcon from 'app/component/save-draft-icon';
import { stringify } from 'querystring';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import axios from 'axios';

// import FilesDragAndDrop from '../../component/files-drag-and-drop'

import DragAndDrop from '../../component/dragUpload/index';

import { toast } from 'react-toastify';

import './document-update.scss';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import UploadFileIcon from 'app/component/upload-icon';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

// import { FileUploader } from "react-drag-drop-files";

import FilesDragAndDrop from '@yelysei/react-files-drag-and-drop';

import DragUploadIcon from 'app/component/upload-drag-icon';

import WordIcon from 'app/component/word-document-icon';
import { padding } from '@mui/system';
import { v4 as uuidv4 } from 'uuid';

export interface Props {
  getDocumentDetail?: any;
  detaildocument?: any;
  count?: any;
  formats?: any;
}

export interface IDocumentUpdateProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const DocumentUpdate = (
  props: IDocumentUpdateProps,
  editMode = true
) => {
  const [isNew, setIsNew] = useState(
    !props.match.params || !props.match.params.id
  );

  const {
    documentEntity,
    loading,
    updating,
    deptList,
    userList,
    detaildocument,
    loadingDepartement,
    loadingDetail,
    approvalList,
    documentListCode,
    acknowledgeList,
  } = props;
  let isChange: boolean = false;

  // if (firstname && lastname && email)
  const [inputType, setType] = useState('');
  const [inputPriority, setPriority] = useState('');
  const [inputSecurity, setSecurity] = useState('');
  const [inputExpDate, setExpDate] = useState('');
  const [listFile, setListFile] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [listAttachment, setListAttachments] = useState([]);
  const [groupIdCode, setgroupIdCode] = useState('');
  const [documentCode, setdocumentCode] = useState('');
  const [documentNumber, setdocumentNumber] = useState('');
  const [isDraft, setIsDraft] = useState(false);
  const [approvalListOptionNew, setApprovalListOptionNew] = useState(
    []
  );
  const [
    acknowledgeListOptionNew,
    setAcknowledgeListOptionNew,
  ] = useState([]);
  const [dataGroup, setDataGroup] = useState<any>({
    value: '',
    label: '',
  });

  const onUpload = (files) => {
    console.log(files);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  function SubmitButton() {
    if (inputType && inputPriority && inputSecurity && contentHTML) {
      return (
        <Button
          onClick={() => (state.button = 2)}
          name="next"
          color="primary"
          id="save-entity"
          type="submit"
          className="pb-2"
          disabled={updating}
        >
          <span className="mr-1">Next</span>
          <ArrowRightIconWhite />
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => (state.button = 2)}
          title="click here"
          name="next"
          color="primary"
          id="save-entity"
          type="submit"
          className="pb-2"
          disabled={true}
        >
          <span className="mr-1">Next</span>
          <ArrowRightIconWhite />
        </Button>
      );
    }
  }

  function SaveToDraftButton() {
    if (inputType && inputPriority && inputSecurity && contentHTML) {
      return (
        <Button
          onClick={() => (state.button = 1)}
          name="next"
          color="primary"
          id="save-entity"
          type="submit"
          className="pb-2"
          disabled={updating}
        >
          <span className="d-none d-md-inline">
            <SaveDraftIcon className="mr-1" />
            Save As Draft
          </span>
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => (state.button = 1)}
          title="click here"
          name="next"
          color="primary"
          id="save-entity"
          type="submit"
          className="pb-2"
          disabled={true}
        >
          <span className="d-none d-md-inline">
            <SaveDraftIcon className="mr-1" />
            Save As Draft
          </span>
        </Button>
      );
    }
  }

  const state = {
    button: 1,
    selectedFile: null,
    selectedFileName: [],
    today: new Date(),
    docDate: new Date(),
    expDocDate: new Date(),
    fileStatus: 0,
    docTypeIsEditable: false,
    arrayFiles: [],
    fileDetails: {},
    // prevDocDate: new Date(),
    // prevExpDocDate: new Date(),
  };

  const handleDateChange = (date) => {
    useState({
      startDate: date,
      // convertDateTimeFromServer
    });
  };

  const [docDate, setDocDate] = useState(new Date());
  const [expDocDate, setExpDocDate] = useState(new Date());

  //storage for department list
  let userListOption = [];

  let approvalListOption = [];
  let acknowledgeListOption = [];
  let documentCodeListOption = [];

  //storage for department list
  let options2 = [];

  const addToArray = (departmentItem) => {
    options2.push({
      value: departmentItem.value,
      label: departmentItem.label,
    });
    // console.log(options2);
  };

  const addToArrayUser = (userItem) => {
    userListOption.push({
      value: userItem.value,
      label: userItem.label,
    });
    // console.log(options2);
  };
  const addToArrayApproval = (userItem) => {
    approvalListOption.push({
      value: userItem.value,
      label: userItem.label,
    });
    // console.log(options2);
  };
  const addToArrayAcknowledge = (userItem) => {
    acknowledgeListOption.push({
      value: userItem.value,
      label: userItem.label,
    });
    // console.log(options2);
  };
  const addToArrayDocumentCode = (userItem) => {
    documentCodeListOption.push({
      value: userItem.value,
      label: userItem.label,
    });
    // console.log(options2);
  };

  const handleClose = () => {
    props.history.push('/draft');
  };

  function Form() {
    function handleSubmit(e) {
      e.preventDefault();
      // console.log('subimt clicked');
    }
  }
  const [loadingLink, setLoadingLink] = useState(false);

  function validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  }

  const checkValidasiURL = (value) => {
    const formData = new FormData();
    formData.append('fileAttachmentLinks', value);
    axios
      .post(
        'services/documentservice/api/dms/validasi_file_attachment',
        formData
      )
      .then((res) => {
        setListAttachments([
          ...listAttachment,
          { id: uuidv4(), value: value },
        ]);
        setInputValue('');
        setLoadingLink(false);
      })
      .catch((err) => {
        setLoadingLink(false);
        toast.error(translate(`${err.response.data.message}`), {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const _handleKeyDown = (e) => {
    setLoadingLink(true);
    console.log(e.target.value);
    console.log('validasi', validateUrl(e.target.value));
    if (validateUrl(inputValue)) {
      checkValidasiURL(inputValue);
    } else {
      setLoadingLink(false);
      toast.error('Link yang dimasukkan kurang tepat', {
        position: 'top-left',
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  let datadata = [];
  let responsestring;
  let userResponseString;
  let approvalResponseString;
  let acknowledgeResponseString;

  const getListAttachment = () => {
    axios
      .get(
        `/services/documentservice/api/dms/get_attachment_from_gcp?fileId=${props.match.params.id}`
      )
      .then((result) => {
        console.log(result.data.data);
        setListFile(result.data.data.content);
      })
      .then((err) => {});
  };

  useEffect(() => {
    props.getDeptList(true);
    responsestring = JSON.stringify(props.deptList);

    props.getUserList(true);
    userResponseString = JSON.stringify(props.userList);
    props.getApprovalList(true);
    approvalResponseString = JSON.stringify(props.approvalList);
    props.getAcknowledgeList(true);
    acknowledgeResponseString = JSON.stringify(props.acknowledgeList);
    props.getListDocumentCode(true);

    if (isNew) {
      props.reset();
    } else {
      props.getDocumentDetail(props.match.params.id);
      getListAttachment();
      // props.getEntity(props.match.params.id);
    }
  }, []);

  const drop = React.useRef(null);
  const [dragging, setDragging] = useState(true);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    drop.current.addEventListener('dragover', handleDragOver);
    drop.current.addEventListener('drop', handleDrop);

    return () => {
      drop.current.removeEventListener('dragover', handleDragOver);
      drop.current.removeEventListener('drop', handleDrop);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    // e.preventDefault();
    // e.stopPropagation();

    setDragging(false);

    const files = e.dataTransfer.files;
    onUpload(files);

    // if (files.length) {
    //     handleFiles(files);
    // }

    // const files = [...e.dataTransfer.files];
    // const {files} = e.dataTransfer.files;

    // if (count && count < files.length) {
    //   console.log(`Only ${count} file${count !== 1 ? 's' : ''} can be uploaded at a time`);
    //   return;
    // }

    // if (formats && files.some((file) => !formats.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase())))) {
    //   console.log(`Only following file formats are acceptable: ${formats.join(', ')}`);
    //   return;
    // }

    // if (files && files.length) {
    //   onUpload(files);
    // }
  };

  const onUploadTrue = (e) => {
    (files) => console.log(files);
    console.log(e.target);
  };

  var datadetail = props.detaildocument;

  let to_previous = [];
  to_previous = props.detaildocument['to']
    ? props.detaildocument['to']
    : [];
  let cc_previous = [];
  cc_previous = props.detaildocument['cc']
    ? props.detaildocument['cc']
    : [];
  let approval_previous = [];
  approval_previous = props.detaildocument['approval']
    ? props.detaildocument['approval']
    : [];

  let acknowledge_previous = [];
  acknowledge_previous = props.detaildocument['acknowledgeList']
    ? props.detaildocument['acknowledgeList']
    : [];

  let contentHTML_previous = [];
  contentHTML_previous = props.detaildocument['fileContent']
    ? props.detaildocument['fileContent']
    : [];

  let prevDocDate;
  let prevDocExpDate;

  const [selected_cc, setSelected_cc] = useState([]);
  const [selected_approval, setSelected_approval] = useState([]);
  const [selected_acknowledge, setSelected_acknowledge] = useState(
    []
  );
  var [contentHTML, setContentHTML] = useState('');

  const [selected_to, setSelected_to] = useState([]);
  const c = [];

  const inputFile = useRef(null);

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
    //  inputFile.current.click();
  };

  const onSelectDocumentCode = (event) => {
    // console.error('onSelectDocumentCode', event)
    console.error('on click', event.value);
    let idDocNum = event.value;
    setgroupIdCode(idDocNum);
    setDataGroup({ label: event.label, value: event.value });

    axios
      .get(
        `services/documentservice/api/dms/generateDocumentNumber?groupId=${idDocNum}`
      )
      .then(async (result) => {
        console.log(result.data);
        setdocumentCode(result.data.data.documentCode);
        setdocumentNumber(result.data.data.documentNumber);
        console.error(documentNumber, documentCode);
      })
      .catch((err) => {});
  };

  const onEditDocumentCode = (data) => {
    // console.error('onSelectDocumentCode', event)
    console.error('on edit ', data);
    let groupId = data.groupId;
    let groupName = data.groupName;
    let documentNumber = data.documentNumber;
    let documentCode = data.documentCode;
    console.error('number nya ', documentNumber);
    setgroupIdCode(groupId);
    setDataGroup({ label: groupName, value: groupId });

    if (documentNumber === 0 || data.fileStatus === 'DRAFT') {
      axios
        .get(
          `services/documentservice/api/dms/generateDocumentNumber?groupId=${groupId}`
        )
        .then(async (result) => {
          console.log(result.data);
          setdocumentCode(result.data.data.documentCode);
          setdocumentNumber(result.data.data.documentNumber);
          console.error(documentNumber, documentCode);
        })
        .catch((err) => {});
    } else {
      setdocumentCode(documentCode);
      setdocumentNumber(documentNumber);
    }
  };

  const handleClick = (event) => {
    inputFile.current.click();
  };

  // const toBase64 = file => new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => resolve(reader.result);
  //   reader.onerror = error => reject(error);

  // });

  let beCon = [];

  var urls = {};

  var count_review = new Array();

  // On file select (from the pop up)
  const onFileChange = (event) => {
    localStorage.removeItem('itemkuasd');
    // localStorage.removeItem("itemkuasd2");
    const fileUploaded = event.target.files;

    console.log('upload');
    console.log(fileUploaded);

    state.selectedFile = fileUploaded;
    console.log('lenght', fileUploaded.length);

    // state.arrayFiles = []

    const entityFiles = {
      files: beCon,
    };

    for (let i = 0; i < fileUploaded.length; i++) {
      (async () => {
        let aksjdhs = await toBase64(fileUploaded[i]);
        // console.log("amzaw", aksjdhs);
        // state.arrayFiles.push(aksjdhs.toString())
        // urls['data'] = aksjdhs
        beCon.push(aksjdhs);
      })();
    }

    console.log('data store', state.arrayFiles);
    console.log('data type', typeof state.arrayFiles);

    console.log('data store2', count_review);
    console.log('data type2', typeof count_review);

    localStorage.setItem(
      'itemkuasd',
      JSON.stringify(state.arrayFiles)
    );
    localStorage.setItem('itemkuasd2', JSON.stringify(entityFiles));
  };

  // On file upload (click the upload button)
  const onFileUpload = () => {
    // Create an object of formData
    const attachData = new FormData();

    // Update the formData object
    attachData.append(
      'myFile',
      state.selectedFile
      // state.selectedFile.name
    );

    localStorage.setItem('datawkowkwo', state.selectedFile);

    // Details of the uploaded file
    console.log(state.selectedFile);

    // Request made to the backend api
    // Send formData object
    // axios.post("api/uploadfile", formData);
    // sessionStorage.setItem('fileAttachment', attachData.selectedFile);
    // localStorage.lname=document.getElementById("file").value;
  };

  const onDocNumberChanged = (data) => {
    console.log(data.target.value);
    setdocumentNumber(data.target.value);
  };

  var respon = JSON.stringify(deptList);
  var dataValue = JSON.parse(respon);

  var responUser = JSON.stringify(userList);
  var dataValueUser = JSON.parse(responUser);

  var responseApproval = JSON.stringify(approvalList);
  var dataValueApproval = JSON.parse(responseApproval);
  var responseAcknowledge = JSON.stringify(acknowledgeList);
  var dataValueAcknowledge = JSON.parse(responseAcknowledge);

  var responsedocumentListCode = JSON.stringify(documentListCode);
  var dataValuedocumentListCode = JSON.parse(
    responsedocumentListCode
  );

  let dataDept = [];
  let dataUsers = [];
  let dataApprovals = [];
  let dataAcknowledge = [];
  let dataDocumentlistcode = [];
  dataValue.forEach((element) => {
    dataDept.push(element);
  });
  // user
  dataValueUser.forEach((element) => {
    dataUsers.push(element);
  });

  dataValueApproval.forEach((element) => {
    dataApprovals.push(element);
  });
  dataValueAcknowledge.forEach((element) => {
    dataAcknowledge.push(element);
  });

  dataValuedocumentListCode.forEach((element) => {
    dataDocumentlistcode.push(element);
  });

  // addToArray({
  //   value: "All Divisions/Teams",
  //   label: "All Divisions/Teams"
  // });

  dataValue.forEach((element) => {
    addToArray({
      value: element.id,
      label: element.groupName,
    });
  });

  dataValueUser.forEach((element) => {
    addToArrayUser({
      value: element.employee_no,
      label: element.full_name,
    });
  });
  dataValueApproval.forEach((element) => {
    addToArrayApproval({
      value: element.employee_no,
      label: element.full_name,
    });
  });
  dataValueAcknowledge.forEach((element) => {
    addToArrayAcknowledge({
      value: element.employee_no,
      label: element.full_name,
    });
  });

  dataValuedocumentListCode.forEach((element) => {
    addToArrayDocumentCode({
      value: element.id,
      label: element.groupName,
    });
  });

  let id_doc;
  if (isNew) {
    id_doc = 0;
  } else {
    id_doc = props.match.params.id;
    // setExpDate(datadetail['expDate'])
  }

  let fileContent = '';

  const handleEditorChange = (value) => {
    isChange = true;
    console.log(isChange);
    console.log('nilai text editor', value.target.getContent());
    //
    fileContent = value.target.getContent();
    if (value.target.getContent() === '') {
      console.log(
        'using previous content:',
        value.target.getContent()
      );
    } else {
      console.log('Content was updated:', value.target.getContent());
      setContentHTML(value.target.getContent());
    }

    // setContent(value.target.value)
  };

  const handleEditorSubmit = () => {
    console.error(isChange);
    if (!isNew) {
      if (isChange == false) {
        console.info('using previous content:');

        //  console.log(contentHTML_previous);

        contentHTML = contentHTML_previous.toString();

        console.log(contentHTML);
        setContentHTML(contentHTML);
        // props.saveDocumentToDraft(entity);
      }
    }
    if (fileContent === '') {
      contentHTML = contentHTML_previous.toString();
      console.error('submit whatt??');
      console.log(contentHTML);
    }
  };

  const handleChange = (event) => {
    const input = event.target;
    const value =
      input.type === 'checkbox' ? input.checked : input.value;
  };

  var result1;
  var result2;
  var result3;
  var docxfile;

  var mammothStyle = {
    styleMap: ["table[style-name='border'] => border-style:dotted"],
  };
  var mammoth = require('mammoth');

  var imageResizerStyle = {
    convertImage: mammoth.images.imgElement(function (image) {
      return image.read('base64').then(function (imageBuffer) {
        return {
          src: 'data:' + image.contentType + ';base64,' + imageBuffer,
          style: 'width:600px;',
        };
      });
    }),
  };

  var mammothOptions = {
    convertImage: mammoth.images.imgElement(function (image) {
      return image.read('base64').then(function (imageBuffer) {
        return {
          src: 'data:' + image.contentType + ';base64,' + imageBuffer,
        };
      });
    }),
  };

  const handleFileUpload = (files) => {
    console.log(files);
    console.log(files[0].name);

    var file = files[0];
    // var office = require('office');
    // office.parse(files, function(err, data) {
    //   console.log(data.sheets);
    // });
    // console.log(mammoth.convertToHtml(files[0], options));

    console.time();
    var reader = new FileReader();
    reader.onloadend = function (event) {
      var arrayBuffer = reader.result;
      // debugger

      console.log('buffer : ', { arrayBuffer: arrayBuffer });

      //original
      // mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, mammothStyle).then(function (resultObject) {

      // image resizer
      mammoth
        .convertToHtml(
          { arrayBuffer: arrayBuffer },
          imageResizerStyle
        )
        .then(function (resultObject) {
          // result1.innerHTML = resultObject.value
          var myStr = resultObject.value;
          var strTable =
            '{<table class="MsoTableGrid" style="border-collapse: collapse; border: none;" border="1" cellspacing="0" cellpadding="0">';
          var newStr = myStr.replaceAll('<table>', strTable);
          // var tdstyle = "<td style=\"border: solid windowtext 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;\" valign=\"top\"><p style=\"margin: 0cm 0cm 0.0001pt; line-height: normal;\">";
          // var newtd = newStr.replaceAll("<td><p>", tdstyle);

          var tdstyle =
            '<td style="border: solid windowtext 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" ';
          var newtd = newStr.replaceAll('<td', tdstyle);
          // var pstyle = "<p style=\"margin: 0cm 0cm 0.0001pt; line-height: normal;\">";
          // var newp = newtd.replaceAll("<p>", pstyle);

          // setContentHTML(resultObject.value);
          setContentHTML(newtd);
          // setContentHTML(newp);
          console.log('convertToHtml', resultObject.value);
        });
      console.timeEnd();

      mammoth
        .extractRawText({ arrayBuffer: arrayBuffer })
        .then(function (resultObject) {
          // result2.innerHTML = resultObject.value
          console.log('extractRawText', resultObject.value);
        });

      mammoth
        .convertToMarkdown({ arrayBuffer: arrayBuffer })
        .then(function (resultObject) {
          // result3.innerHTML = resultObject.value
          // console.log("convertToMarkdown", resultObject.value)
        });
    };
    reader.readAsArrayBuffer(file);
    // sessionStorage.setItem('docxfile', files[0].name);
    setDragging(false);

    // docxfile.innerHTML = sessionStorage.getItem('docxfile');

    document.getElementById('name').innerHTML = files[0].name;
  };

  var options = {
    styleMap: [
      "p[style-name='Section Title'] => h1:fresh",
      "p[style-name='Subsection Title'] => h2:fresh",
    ],
  };
  // mammoth.convertToHtml({path: "path/to/document.docx"}, options);

  const handleFormSubmit = (entity) => {
    console.log(entity);
    if (fileContent === '') {
      console.log('fileContent empty');
    } else {
      sessionStorage.setItem('fileContent', entity.fileContent);
      sessionStorage.setItem('type', entity.type);
      sessionStorage.setItem('priority', entity.priority);
      sessionStorage.setItem(
        'documentSecurity',
        entity.documentSecurity
      );
      sessionStorage.setItem('to', JSON.stringify(entity.to));
      sessionStorage.setItem('cc', JSON.stringify(entity.cc));
      sessionStorage.setItem('regarding', entity.regarding);
      sessionStorage.setItem('date', entity.date);
      sessionStorage.setItem('expDate', entity.expDate);
      sessionStorage.setItem(
        'approval',
        JSON.stringify(entity.approval)
      );
      sessionStorage.setItem(
        'acknowledge',
        JSON.stringify(entity.acknowledge)
      );
      // props.documentPreview(entity);
      open('/preview/new', fileContent);
    }
  };

  function addHours(date, hours) {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hours);
    return newDate;
  }

  const [disabledDocument, setDisabledDocument] = useState(false);

  if (!isNew) {
    useEffect(() => {
      console.log('ini props data', props);

      axios
        .get('/services/documentservice/api/documents/' + id_doc)
        .then((res) => {
          console.error('hasil : ', res);
          // console.error('to', res.data.data.to)

          setContentHTML(res.data.data.fileContent);
          res.data.data.to.forEach((item) => {
            selected_to.push({
              value: item?.branchCode,
              label: item?.branchName,
            });
          });

          res.data.data.cc.forEach((item) => {
            selected_cc.push({
              value: item?.branchCode,
              label: item?.branchName,
            });
          });

          res.data.data.acknowledgeList.forEach((item) => {
            selected_acknowledge.push({
              value: item?.employeeNo,
              label: item?.employeeName,
            });
          });

          res.data.data.approval.forEach((item) => {
            selected_approval.push({
              value: item?.employeeNo,
              label: item?.employeeName,
            });
          });

          // expDate

          // setExpDocDate

          // console.error("prevexop date ->")
          console.error('expdate :', res.data.data.expDate);

          if (new Date(res.data.data.expDate) < state.today) {
            console.error('Selected date is in the PAST');
            setExpDocDate(state.today);
          } else if (
            new Date(res.data.data.expDate) === state.today
          ) {
            console.error('Selected date today');
            setExpDocDate(state.today);
          } else if (new Date(res.data.data.expDate) > state.today) {
            console.error('Selected date is in the FUTURE');
            // console.error(expDocDate);
            setExpDocDate(new Date(res.data.data.expDate));
            // console.error(expDocDate);
          } else {
            console.error('date else');
          }

          // console.error('fille Status :', res.data.data.fileStatus)
          if (res.data.data.fileStatus === 'APPROVED_BY_REJECTOR') {
            state.fileStatus = 2;

            console.error('fille Status code :', state.fileStatus);
            // state.docTypeIsEditable = true;

            setDisabledDocument(true);
            console.error(
              'docTypeIsEditable code :',
              state.docTypeIsEditable
            );
          }
        })
        .catch((err) => {
          console.error(err.response);
          toast.error(translate(`${err.response.data.message}`));
        });
    }, []);
  }

  const replaceP = (data) => {
    var str = data; /* however you get it */
    str = str.replace(/^\<p\>/, '').replace(/\<\/p\>$/, '');
    return str;
  };

  function subtractHours(numOfHours, date = new Date()) {
    date.setHours(date.getHours() + numOfHours);

    return date;
  }

  const saveEntity = (event, errors, values) => {
    console.log('saveEntity');
    // values.date = convertDateTimeToServer(values.date);
    // docDate
    // let dateDump = docDate.setHours(docDate.getHours() - 1);

    const gmt7Date = addHours(docDate, 7);
    const gmt7ExpDate = addHours(expDocDate, 7);
    values.date = convertDateTimeToServer(gmt7Date);
    values.expDate = convertDateTimeToServer(gmt7ExpDate);

    // values.expDate = convertDateTimeToServer(values.expDate);
    // values.createdDate = convertDateTimeToServer(values.createdDate);
    // values.lastModifiedDate = convertDateTimeToServer(values.lastModifiedDate);

    let to_data = [];
    let cc_data = [];
    let approval_data = [];
    let acknowledge_data = [];

    let ibranch: IBranchCode;
    selected_to.forEach((element) => {
      let ibranch: IBranchCode;
      ibranch = {
        ...ibranch,
        branchCode: element.value,
      };
      to_data.push(ibranch);
    });

    let ibranch2: IBranchCode;
    selected_cc.forEach((element) => {
      let ibranch2: IBranchCode;
      ibranch2 = {
        ...ibranch2,
        branchCode: element.value,
      };
      cc_data.push(ibranch2);
    });

    let iapproval: IApproval;
    selected_approval.forEach((element) => {
      let iapproval: IApproval;
      iapproval = {
        ...iapproval,
        employeeNo: element.value,
      };
      approval_data.push(iapproval);
    });

    let iacknowledge: IApproval;
    selected_acknowledge.forEach((element) => {
      let iacknowledge: IApproval;
      iacknowledge = {
        ...iacknowledge,
        employeeNo: element.value,
      };
      acknowledge_data.push(iacknowledge);
    });

    if (errors.length === 0) {
      const entity = {
        ...documentEntity,
        ...values,
        id: id_doc,
        to: to_data,
        cc: cc_data,
        fileContent: contentHTML,
        approval: approval_data,
        acknowledge: acknowledge_data,
        groupId: groupIdCode ? groupIdCode : null,
        documentNumber: documentNumber ? documentNumber : null,
        documentCode: documentCode ? documentCode : null,
        date: subtractHours(0, values.date),
        expDate: subtractHours(0, values.expDate),
      };

      const localentity = {
        to: selected_to,
        cc: selected_cc,
        approval: selected_approval,
        acknowledge: selected_acknowledge,
        id_doc: dataGroup,
        id: id_doc,
        documentNumber: documentNumber,
        documentCode: documentCode,
        isNew: isNew,
        isDraft: isDraft,
        isDisabledDocument: disabledDocument,
      };

      console.log('*********** ID *************');
      console.log(entity);
      console.log(isNew);

      if (isNew) {
        if (state.button === 1) {
          // alert("Button 1 clicked!");
          // console.log(entity.id);
          // props.saveDocumentToDraft(entity);
          const formData = new FormData();
          formData.append('data', JSON.stringify(entity));
          // formData.append("signatureImage", sign);
          listFile.map((item, index) => {
            console.log(item);
            if (!item.fileAttachmentId) {
              formData.append(
                'fileAttachment',
                dataURLtoFile(item.file, item.fileName)
              );
            }
          });
          // link gdrive
          listAttachment.map((data, i) => {
            formData.append('fileAttachmentLinks', data.value);
          });
          axios
            .put(
              '/services/documentservice/api/dms/save_as_draft',
              formData
            )
            .then((res) => {
              console.log(res);
              props.history.replace('/draft');
            })
            .catch((err) => {});
        }
        if (state.button === 2) {
          if (contentHTML != '') {
            console.log('Button 2 clicked!');
            console.log('ini entity sebelum', entity);

            localStorage.setItem(
              'datapreview',
              JSON.stringify(entity)
            );
            localStorage.setItem(
              'datamulti',
              JSON.stringify(localentity)
            );
            localStorage.setItem(
              'attachment',
              JSON.stringify(state.selectedFile)
            );
            // hit api
            entity.fileContent = replaceP(entity.fileContent);
            console.log('ini data entity sesudah', entity);
            axios
              .post(
                '/services/documentservice/api/documents/get-data-for-preview',
                entity
              )
              .then((res) => {
                console.log('hasil', res);
                props.history.push('/preview/document/pdf', {
                  data: JSON.stringify(entity),
                  file: JSON.stringify(listFile),
                  link: JSON.stringify(listAttachment),
                });
              })
              .catch((err) => {
                console.log(err.response);
                toast.error(
                  translate(`${err.response.data.message}`)
                );
              });

            // if success
          } else {
            // console.log("kentang");
            console.log('using previous content value!');
            contentHTML = contentHTML_previous.toString();
          }
        }
        if (state.button === 3) {
          console.log('Button 3 clicked!');
          handleFormSubmit(entity);
        }
      } else {
        if (state.button === 1) {
          if (contentHTML != '') {
            console.log('file content data', contentHTML);
            console.log('Button 1 clicked! edit');
            console.log(entity.id);
            // props.saveDocumentToDraft(entity);
            const formData = new FormData();
            formData.append('data', JSON.stringify(entity));
            // formData.append("signatureImage", sign);
            listFile.map((item, index) => {
              console.log(item);
              if (!item.fileAttachmentId) {
                formData.append(
                  'fileAttachment',
                  dataURLtoFile(item.file, item.fileName)
                );
              }
            });
            listAttachment.map((data, i) => {
              formData.append('fileAttachmentLinks', data.value);
            });
            axios
              .put(
                '/services/documentservice/api/dms/save_as_draft',
                formData
              )
              .then((res) => {
                console.log(res);
                props.history.replace('/draft');
              })
              .catch((err) => {});
          } else {
            // handleEditorSubmit();
            saveEntity(event, errors, values);
          }
        }
        if (state.button === 2) {
          if (contentHTML != '') {
            console.log('Button 2 clicked!');
            console.log('entity sebelum', entity);

            localStorage.setItem(
              'datapreview',
              JSON.stringify(entity)
            );
            localStorage.setItem(
              'datamulti',
              JSON.stringify(localentity)
            );

            // localStorage.setItem('attachment', JSON.stringify(state.selectedFile))
            console.log('entity sesudah', entity);
            entity.fileContent = replaceP(entity.fileContent);
            axios
              .post(
                '/services/documentservice/api/documents/get-data-for-preview',
                entity
              )
              .then((res) => {
                console.log('hasil', res);
                props.history.push('/preview/document/pdf', {
                  data: JSON.stringify(entity),
                  file: JSON.stringify(listFile),
                  link: JSON.stringify(listAttachment),
                });
              })
              .catch((err) => {
                console.log(err.response);
                toast.error(
                  translate(`${err.response.data.message}`)
                );
              });
            // hit api

            // if success
          } else {
            console.log('using previous content value!');
            contentHTML = contentHTML_previous.toString();
            console.log(contentHTML);
            saveEntity(event, errors, values);
          }
        }
        if (state.button === 3) {
          console.log('Button 3 clicked!');
          // props.sendNewDocument(entity);
          handleFormSubmit(entity);
        }
      }
    }
  };

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const deleteDocxFile = (e) => {
    setDragging(true);
    console.log('delete uploaded docx');
    setContentHTML('');
  };

  const handleFileInput = async (e) => {
    if (e.target.files.length < 1) {
      return;
    }

    console.log('ini list file', e.target.files);
    console.log('select', e.target.files[0]);
    const file = e.target.files[0];
    console.log('type', file);
    console.log('type', isValidFileUploaded(file));

    let filesSelect = e.target.files;
    console.log('dari map', filesSelect);

    Array.from(filesSelect).forEach(async (item: any) => {
      if (!isValidFileUploaded(item)) {
        toast.error('Tipe file tidak diperbolehkan', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        //file is valid
        if (!validateSize(item)) {
          toast.error('Ukuran file Tidak Boleh Lebih Dari 10MB', {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          let data = {
            fileAttachmentId: null,
            fileName: item.name,
            file: await toBase64(item),
            fileContentType: item.type,
            fileSize: item.size,
          };
          setListFile((oldArray) => [...oldArray, data]);
        }
      }
    });
  };

  useEffect(() => {}, [listFile]);

  const getAcknowledge = (params?: any, data?: any) => {
    if (!params) {
      let urlparams = '?';
      selected_to.map((item, index) => {
        urlparams = urlparams + `groups=${item.value}&`;
      });

      selected_cc.map((item, index) => {
        urlparams = urlparams + `groups=${item.value}&`;
      });

      if (groupIdCode) {
        urlparams = urlparams + `groups=${groupIdCode}&`;
      }

      console.log(urlparams);

      axios
        .get(
          `/services/uaadocservice/api/users/get-acknowledges-by-certain-groups${
            urlparams != '?' ? urlparams : ''
          }`
        )
        .then((result) => {
          console.log(result.data);
          let dataApproval = [];
          result.data.map((item, index) => {
            dataApproval.push({
              label: item.full_name,
              value: item.employee_no,
            });
          });

          setAcknowledgeListOptionNew(dataApproval);
        })
        .catch((err) => {});
    } else {
      let urlparams = '?';
      params.to.map((item, index) => {
        urlparams = urlparams + `groups=${item.value}&`;
      });

      params.cc.map((item, index) => {
        urlparams = urlparams + `groups=${item.value}&`;
      });

      console.log(urlparams);

      return axios
        .get(
          `/services/uaadocservice/api/users/get-acknowledges-by-certain-groups${
            urlparams != '?' ? urlparams : ''
          }`
        )
        .then((result) => {
          console.log(result.data);
          let dataApproval = [];
          result.data.map((item, index) => {
            dataApproval.push({
              label: item.full_name,
              value: item.employee_no,
            });
          });

          return dataApproval;
        })
        .catch((err) => {});
    }
  };
  const getApproval = (params?: any, data?: any) => {
    // console.log('trigger', selected_to)

    if (!params) {
      let urlparams = '?';
      selected_to.map((item, index) => {
        urlparams = urlparams + `groups=${item.value}&`;
      });

      selected_cc.map((item, index) => {
        urlparams = urlparams + `groups=${item.value}&`;
      });

      if (groupIdCode) {
        urlparams = urlparams + `groups=${groupIdCode}&`;
      }

      console.log(urlparams);

      axios
        .get(
          `/services/uaadocservice/api/users/get-approvers-by-certain-groups${
            urlparams != '?' ? urlparams : ''
          }`
        )
        .then((result) => {
          console.log(result.data);
          let dataApproval = [];
          result.data.map((item, index) => {
            dataApproval.push({
              label: item.full_name,
              value: item.employee_no,
            });
          });

          setApprovalListOptionNew(dataApproval);
        })
        .catch((err) => {});
    } else {
      let urlparams = '?';
      params.to.map((item, index) => {
        urlparams = urlparams + `groups=${item.value}&`;
      });

      params.cc.map((item, index) => {
        urlparams = urlparams + `groups=${item.value}&`;
      });

      return axios
        .get(
          `/services/uaadocservice/api/users/get-approvers-by-certain-groups${
            urlparams != '?' ? urlparams : ''
          }`
        )
        .then((result) => {
          console.log(result.data);
          let dataApproval = [];
          result.data.map((item, index) => {
            dataApproval.push({
              label: item.full_name,
              value: item.employee_no,
            });
          });
          return dataApproval;

          // setListApproval(dataApproval)
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    getApproval();
    getAcknowledge();
  }, [selected_to, selected_cc, groupIdCode]);

  // useEffect(() => {

  //   console.log('trigger', selected_to)
  //   let urlparams = '?'
  //   selected_to.map((item, index) => {
  //     urlparams = urlparams + `groups=${item.value}&`
  //   })

  //   selected_cc.map((item, index) => {
  //     urlparams = urlparams + `groups=${item.value}&`
  //   })

  //  if(selected_to.length == 0) {
  //     setApprovalListOptionNew([])
  //     // setSelected_approval([])
  //  } else {
  //   axios.get(`/services/uaadocservice/api/users/get-approvers-by-certain-groups${urlparams ? urlparams : ''}`).then(result => {
  //     console.log(result.data)
  //     let dataApproval = []
  //     result.data.map((item, index) => {
  //         dataApproval.push({
  //           label: item.full_name,
  //           value: item.employee_no
  //         })
  //     })

  //     console.log(dataApproval)
  //     setApprovalListOptionNew(dataApproval)
  //     setSelected_approval([])
  //   }).catch(err => {
  //     setApprovalListOptionNew([])
  //     setSelected_approval([])
  //   })
  //  }
  // }, [selected_to, selected_cc])

  // useEffect(() => {
  //   console.log('trigger', selected_cc)

  //   let urlparams = '?'
  //   selected_cc.map((item, index) => {
  //     urlparams = urlparams + `groups=${item.value}&`
  //   })

  //   selected_to.map((item, index) => {
  //     urlparams = urlparams + `groups=${item.value}&`
  //   })

  //  if(selected_cc.length == 0) {
  //   setAcknowledgeListOptionNew([])
  //   // setSelected_acknowledge([])
  //  } else {
  //   axios.get(`/services/uaadocservice/api/users/get-acknowledges-by-certain-groups${urlparams ? urlparams : ''}`).then(result => {
  //     console.log(result.data)
  //     let dataApproval = []
  //     result.data.map((item, index) => {
  //         dataApproval.push({
  //           label: item.full_name,
  //           value: item.employee_no
  //         })
  //     })

  //     console.log(dataApproval)
  //     setAcknowledgeListOptionNew(dataApproval)
  //     setSelected_acknowledge([])
  //   }).catch(err => {
  //     setAcknowledgeListOptionNew([])
  //     setSelected_acknowledge([])
  //   })
  //  }
  // }, [selected_cc, selected_to])

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const isValidFileUploaded = (file) => {
    console.log('type', file.type);
    if (file.type == '') {
      return true;
    } else {
      console.log('type', file.type);
      console.log('type', file.type.split('/')[1]);
      const validExtensions = [
        'png',
        'jpg',
        'jpeg',
        'docx',
        'xlsx',
        'pdf',
        'csv',
        'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];
      const fileExtension = file.type.split('/')[1];
      console.log(validExtensions.includes(fileExtension));
      return validExtensions.includes(fileExtension);
    }
  };

  function validateSize(input) {
    const fileSize = input.size / 1024 / 1024; // in MiB
    if (fileSize > 10) {
      return false;
    } else {
      return true;
    }
  }

  const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  };

  const deleteFile = (key) => {
    let arr = listFile;
    var filteredArray = arr.filter(function (e, i) {
      return i !== key;
    });
    setListFile(filteredArray);
  };

  const deleteLink = (key) => {
    let arr = listAttachment;
    var filteredArray = arr.filter(function (e, i) {
      return e.id !== key;
    });
    setListAttachments(filteredArray);
  };

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (
      let offset = 0;
      offset < byteCharacters.length;
      offset += sliceSize
    ) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const openFilePreview = (base64, filetype) => {
    const blob = b64toBlob(base64, filetype);
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl);
  };

  const deleteFileUpload = (fileAttachmentId) => {
    // /services/documentservice/api/dms/delete-attachment?fileAttachmentId=40454&fileId=40362
    axios
      .delete(
        `/services/documentservice/api/dms/delete-attachment?fileAttachmentId=${fileAttachmentId}&fileId=${props.match.params.id}`
      )
      .then((result) => {
        let arr = listFile;
        var filteredArray = arr.filter(function (e, i) {
          return e.fileAttachmentId !== fileAttachmentId;
        });
        setListFile(filteredArray);
        toast.success('Berhasil Menghapus File', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error('Gagal Menghapus File', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div className="container">
      <Row className="">
        <Col md="8">
          <h2 id="documenttestApp.document.home.createOrEditLabel">
            <Translate contentKey="create.label">
              Create or edit a Document
            </Translate>
          </h2>
          <p id="documenttestApp.document.home.createOrEditLabel">
            <Translate contentKey="create.description">
              Create or edit a Document
            </Translate>
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading && loadingDepartement && loadingDetail ? (
            <p>Loading...</p>
          ) : (
            <AvForm
              model={isNew ? {} : documentEntity}
              onSubmit={saveEntity}
            >
              {/* {!isNew ? (
                <AvGroup>
                  <Label htmlFor="document-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput disabled={true} id="document-id" value={props.match.params.id} type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null} */}

              <div className="card  mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <AvGroup>
                        <div className="form-group row">
                          <label
                            htmlFor="doc_type"
                            className="col-sm-4 col-form-label"
                          >
                            Type:
                          </label>
                          <div className="col-sm-8">
                            <AvField
                              disabled={disabledDocument}
                              type="select"
                              value={
                                isNew ? null : datadetail['type']
                              }
                              id="SalaryType"
                              name="type"
                              helpMessage=""
                              required
                              onChange={(e) =>
                                setType(e.target.value)
                              }
                            >
                              <option value=""></option>
                              <option value="IMO">
                                Internal Memo
                              </option>
                              <option disabled={true} value="MOM">
                                MOM
                              </option>
                              <option disabled={true} value="3">
                                SOP
                              </option>
                              {/* <option disabled={true} value="4">Agreement</option>
              <option disabled={true} value="5">Policy</option>
              <option disabled={true} value="6">Monitoring</option>
              <option disabled={true} value="7">Evaluation</option>
              <option disabled={true} value="8">Mail</option>
              <option disabled={true} value="9">Operational Checklist</option> */}
                            </AvField>
                          </div>
                        </div>
                      </AvGroup>

                      {!isDraft ? (
                        <AvGroup>
                          <div className="form-group row">
                            <label
                              htmlFor="doc_security"
                              className="col-sm-4 col-form-label"
                            >
                              {' '}
                              Document Number:{' '}
                            </label>
                            <div className="col-sm-8">
                              {/* <Select options={documentCodeListOption} value={isNew ? null : groupIdCode } onChange={value => onSelectDocumentCode(66)} /> */}
                              <div
                                className="docNumber"
                                style={{ cursor: 'none' }}
                              >
                                <Select
                                  name="group"
                                  value={dataGroup}
                                  // isDisabled="true"
                                  placeholder="Find Name Or Division"
                                  options={documentCodeListOption.map(
                                    (e) => ({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  )}
                                  onChange={(value) =>
                                    onSelectDocumentCode(value)
                                  }
                                />
                              </div>

                              <br />
                              <div className="form-group row">
                                <br />
                                <div className="col-sm-4">
                                  <AvInput
                                    id="document-id"
                                    pattern="[0-9]*"
                                    value={documentNumber}
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    name="id"
                                    onChange={(value) =>
                                      onDocNumberChanged(value)
                                    }
                                  />
                                </div>
                                <div className="col-sm-8">
                                  <AvInput
                                    id="document-id"
                                    value={documentCode}
                                    type="text"
                                    className="form-control"
                                    name="id"
                                    required
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </AvGroup>
                      ) : (
                        <AvGroup>
                          <div className="form-group row">
                            <label
                              htmlFor="doc_security"
                              className="col-sm-4 col-form-label"
                            >
                              {' '}
                              Document Number:{' '}
                            </label>
                            <div className="col-sm-8">
                              {/* <Select options={documentCodeListOption} value={isNew ? null : groupIdCode } onChange={value => onSelectDocumentCode(66)} /> */}
                              <div
                                className="docNumber"
                                style={{ cursor: 'none' }}
                              >
                                <Select
                                  name="group"
                                  value={dataGroup}
                                  // isDisabled="true"
                                  placeholder="Find Name Or Division"
                                  options={documentCodeListOption.map(
                                    (e) => ({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  )}
                                  onChange={(value) =>
                                    onSelectDocumentCode(value)
                                  }
                                />
                              </div>

                              <br />
                              <div className="form-group row">
                                <br />
                                <div className="col-sm-4">
                                  <AvInput
                                    id="document-id"
                                    pattern="[0-9]*"
                                    value={documentNumber}
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    name="id"
                                    onChange={(value) =>
                                      onDocNumberChanged(value)
                                    }
                                  />
                                </div>
                                <div className="col-sm-8">
                                  <AvInput
                                    id="document-id"
                                    value={documentCode}
                                    type="text"
                                    className="form-control"
                                    name="id"
                                    required
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </AvGroup>
                      )}
                      <div className="row">
                        <div className="col-4"></div>
                        <div className="col-8">
                          <span style={{ fontSize: 10 }}>
                            The document number will only be saved
                            after you send your document
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <AvGroup>
                        <div className="form-group row">
                          <label
                            htmlFor="priority"
                            className="col-sm-4 col-form-label"
                          >
                            Priority:
                          </label>
                          <div className="col-sm-8">
                            <AvField
                              disabled={disabledDocument}
                              type="select"
                              value={
                                isNew ? null : datadetail['priority']
                              }
                              id="SalaryType"
                              name="priority"
                              helpMessage=""
                              required
                              onChange={(e) =>
                                setPriority(e.target.value)
                              }
                            >
                              <option value=""></option>
                              <option value="1">HIGH</option>
                              <option value="2">MEDIUM</option>
                              <option value="3">LOW</option>
                            </AvField>
                          </div>
                        </div>
                      </AvGroup>

                      <AvGroup>
                        <div className="form-group row">
                          <label
                            htmlFor="doc_security"
                            className="col-sm-4 col-form-label"
                          >
                            Document Security:
                          </label>
                          <div className="col-sm-8">
                            <AvField
                              disabled={disabledDocument}
                              type="select"
                              value={
                                isNew
                                  ? null
                                  : datadetail['documentSecurity']
                              }
                              id="SalaryType"
                              name="documentSecurity"
                              helpMessage=""
                              required
                              onChange={(e) =>
                                setSecurity(e.target.value)
                              }
                            >
                              <option value=""></option>
                              <option value="1">
                                Strict Confidential
                              </option>
                              <option value="2">Confidential</option>
                              <option value="3">General</option>
                            </AvField>
                          </div>
                        </div>
                      </AvGroup>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card  mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group row">
                        <label
                          htmlFor="to"
                          className="col-sm-2 col-form-label"
                        >
                          to :
                        </label>
                        {/* <div id="toChoice" className="col-sm-10"> */}
                        <div className="col-sm-10">
                          <MultiSelect
                            overrideStrings={{
                              allItemsAreSelected:
                                'All Divisions/Teams are Selected',
                              selectAll: 'Select All Divisions/Teams',
                              search: 'Search',
                            }}
                            options={options2}
                            value={selected_to}
                            onChange={setSelected_to}
                            labelledBy="Select"
                            disabled={disabledDocument}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group row">
                        <label
                          htmlFor="cc"
                          className="col-sm-2 col-form-label"
                        >
                          cc :
                        </label>
                        {/* <div id="toChoice" className="col-sm-10"> */}
                        <div className="col-sm-10">
                          <MultiSelect
                            hasSelectAll={false}
                            options={options2}
                            value={selected_cc}
                            onChange={setSelected_cc}
                            labelledBy="Select2"
                            disabled={disabledDocument}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <AvGroup>
                        <div className="form-group row">
                          <label
                            htmlFor="doc_title"
                            className="col-sm-2 col-form-label"
                          >
                            Regarding :
                          </label>
                          <div className="col-sm-10">
                            <AvField
                              id="document-createdBy"
                              value={
                                isNew ? null : datadetail['regarding']
                              }
                              type="text"
                              name="regarding"
                              onPaste={(e) => {
                                console.log(
                                  e.clipboardData.getData('Text')
                                );
                                // console.log(e);
                                // console.log(e.clipboardData.getData('text/html'));

                                console.log(e.clipboardData.items[0]);
                              }}
                              maxLength="250"
                              disabled={disabledDocument}
                            />
                          </div>
                        </div>
                      </AvGroup>
                    </div>
                  </div>
                </div>
              </div>

              {isNew ? (
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6">
                        <AvGroup>
                          <div className="form-group row">
                            <label
                              htmlFor="document-date"
                              className="col-sm-4 col-form-label"
                            >
                              Date:
                            </label>
                            <div className="col-sm-8">
                              <div className="date-picker-wrapper">
                                <DatePicker
                                  selected={docDate}
                                  disabled={true}
                                  onChange={(date) =>
                                    setDocDate(date)
                                  }
                                  showTimeSelect
                                  minDate={state.today}
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  timeCaption="time"
                                  // dateFormat="MMMM d, yyyy h:mm aa"
                                  dateFormat="d MMMM yyyy - h:mm aa"
                                  // value={isNew ? state.today : convertDateTimeFromServer(datadetail['expDate']).format('YYYY-MM-DDThh:mm:ss')}

                                  value={
                                    isNew
                                      ? state.today
                                      : new Date(datadetail['date'])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </AvGroup>
                      </div>

                      <div className="col-6">
                        <AvGroup>
                          <div className="form-group row">
                            <label
                              htmlFor="document-exp-date"
                              className="col-sm-4 col-form-label"
                            >
                              Exp Date:
                            </label>
                            <div className="col-sm-8">
                              <div className="date-picker-wrapper">
                                <DatePicker
                                  selected={expDocDate}
                                  // selected={new Date(prevDocExpDate)}
                                  onChange={(date) =>
                                    setExpDocDate(date)
                                  }
                                  showTimeSelect
                                  minDate={state.today}
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  timeCaption="time"
                                  // dateFormat="MMMM d, yyyy h:mm aa"
                                  dateFormat="d MMMM yyyy - h:mm aa"
                                  // value={isNew ? state.today : new Date(datadetail['expDate'])}
                                />
                              </div>
                            </div>
                          </div>
                        </AvGroup>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6">
                        <AvGroup>
                          <div className="form-group row">
                            <label
                              htmlFor="document-date"
                              className="col-sm-4 col-form-label"
                            >
                              Date:
                            </label>
                            <div className="col-sm-8">
                              <div className="date-picker-wrapper">
                                <DatePicker
                                  selected={docDate}
                                  disabled={true}
                                  onChange={(date) =>
                                    setDocDate(date)
                                  }
                                  showTimeSelect
                                  minDate={state.today}
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  timeCaption="time"
                                  // dateFormat="MMMM d, yyyy h:mm aa"
                                  dateFormat="d MMMM yyyy - h:mm aa"
                                  // value={isNew ? state.today : convertDateTimeFromServer(datadetail['expDate']).format('YYYY-MM-DDThh:mm:ss')}

                                  value={
                                    isNew
                                      ? state.today
                                      : new Date(datadetail['date'])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </AvGroup>
                      </div>

                      <div className="col-6">
                        <AvGroup>
                          <div className="form-group row">
                            <label
                              htmlFor="document-exp-date"
                              className="col-sm-4 col-form-label"
                            >
                              Exp Date:
                            </label>
                            <div className="col-sm-8">
                              <div className="date-picker-wrapper">
                                <DatePicker
                                  selected={expDocDate}
                                  onChange={(date) =>
                                    setExpDocDate(date)
                                  }
                                  showTimeSelect
                                  minDate={expDocDate}
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  timeCaption="time"
                                  // dateFormat="MMMM d, yyyy h:mm aa"
                                  dateFormat="d MMMM yyyy - h:mm aa"
                                  // value={isNew ? state.today : new Date(datadetail['expDate'])}
                                />
                              </div>
                            </div>
                          </div>
                        </AvGroup>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="card shadow-lg mb-4">
              
                <div className="card-body">
                  

              <div className="col-12">
                      <AvGroup>
                      <div className="form-group row">
                        <label htmlFor="doc_title" className="col-sm-2 col-form-label">File  Content :</label>
                        <div className="col-sm-10">
                          
                          <AvField id="document-createdBy" type="text" name="fileContent" />
                        </div>
                      </div>
                      </AvGroup>

                    </div>
                    </div>
                    </div> */}

              <div className="bg-white rounded shadow my-4 px-3 py-4">
                <div className="row">
                  <div
                    id="FilesDragAndDrop"
                    ref={drop}
                    className="FilesDragAndDrop"
                  >
                    {/* https://www.npmjs.com/package/@yelysei/react-files-drag-and-drop */}

                    {dragging ? (
                      <FilesDragAndDrop
                        // onUpload={(files) => console.log(files)}
                        onUpload={(files) => handleFileUpload(files)}
                        // onUpload={onUploadTrue}
                        // onDrop={handleDrop}
                        count={1}
                        formats={['docx']}
                        successText={'Upload Success'}
                        successTime={1000}
                        errorTime={2000}
                        containerStyles={{
                          width: '100%',
                          height: '100%',
                          borderRadius: 12,
                          border: '1px dashed #cccccc',
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundColor: '#f5f5f5',
                          paddingTop: '16px',
                          paddingBottom: '16px',
                          paddingLeft: '16px',
                          paddingRight: '16px',
                        }}
                        openDialogOnClick
                      >
                        <div>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <div>
                              Drag and drop or click here to Upload
                              DOCX file
                            </div>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <DragUploadIcon />
                          </div>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </FilesDragAndDrop>
                    ) : (
                      <div>
                        <FilesDragAndDrop
                          // onUpload={(files) => console.log(files)}
                          onUpload={(files) =>
                            handleFileUpload(files)
                          }
                          // onUpload={onUploadTrue}
                          // onDrop={handleDrop}
                          count={1}
                          formats={['docx']}
                          successText={'Upload Success'}
                          successTime={1000}
                          errorTime={2000}
                          containerStyles={{
                            width: '100%',
                            height: '100%',
                            borderRadius: 12,
                            border: '1px dashed #cccccc',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: '#FBDEC9',
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                          }}
                          openDialogOnClick
                        >
                          <div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />

                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <WordIcon />
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {/* <div></div> */}
                              <p>
                                File <span id="name"></span> Uploaded.
                              </p>
                            </div>

                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                          </div>
                        </FilesDragAndDrop>
                        <div
                          style={{
                            paddingTop: '16px',
                            paddingLeft: '16px',
                          }}
                          className="float-right"
                        >
                          <Button
                            color="primary"
                            onClick={(files) => deleteDocxFile(files)}
                          >
                            Remove File{' '}
                            <FontAwesomeIcon
                              icon="trash"
                              color="red"
                            />
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="card  mb-4">
                <div className="card-body">
                  <div className="row">
                    <p>
                      Signing orders are determined by the order of
                      selection
                    </p>
                    <div className="col-12">
                      <div className="form-group row">
                        <label
                          htmlFor="to"
                          className="col-sm-2 col-form-label"
                        >
                          Approval :
                        </label>
                        <div className="col-sm-10">
                          <MultiSelect
                            hasSelectAll={false}
                            options={approvalListOptionNew}
                            value={selected_approval}
                            onChange={setSelected_approval}
                            labelledBy="SelectApproval"
                            disabled={disabledDocument}
                          />
                          <span className="" style={{ fontSize: 10 }}>
                            This Approval list is sent in order based
                            on your choice
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group row">
                        <label
                          htmlFor="cc"
                          className="col-sm-2 col-form-label"
                        >
                          Acknowledge :
                        </label>
                        <div className="col-sm-10">
                          <MultiSelect
                            hasSelectAll={false}
                            options={acknowledgeListOptionNew}
                            value={selected_acknowledge}
                            onChange={setSelected_acknowledge}
                            labelledBy="SelectAcknowldge"
                            disabled={disabledDocument}
                          />
                          <span className="" style={{ fontSize: 10 }}>
                            This Approval list is sent in order based
                            on your choice
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="card  mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">

                      <div>

                        &nbsp;
                        <input type="file" ref={inputFile} multiple onChange={onFileChange} />
                      </div>
                      &nbsp;

                      <Button onClick={onFileUpload}>
                        upload
                      </Button>



                      <p>
                        or input link
                      </p>
                      <AvField disabled={true} id="linkattachment" type="text" name="link" />
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="bg-white rounded shadow my-4 px-4 py-4">
                <h5>Attachment</h5>

                {listFile.length > 0 ? (
                  listFile.map((item, index) => {
                    return (
                      <>
                        <div>
                          <div
                            className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block"
                            style={{
                              background: '#F5F5F5',
                              color: '#3267E3',
                              alignContent: 'c',
                            }}
                            key={`file-${index}`}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <span
                                onClick={() =>
                                  openFilePreview(
                                    item.file?.replace(/^[^,]+,/, ''),
                                    item.fileContentType
                                  )
                                }
                              >
                                {item.fileName} ({' '}
                                {bytesToSize(item.fileSize)})
                              </span>
                              {item.fileAttachmentId ? (
                                <span
                                  className="px-2 py-2 mx-2"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    deleteFileUpload(
                                      item.fileAttachmentId
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="trash"
                                    color="red"
                                  />
                                </span>
                              ) : (
                                <span
                                  className="px-2 py-2 mx-2"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => deleteFile(index)}
                                >
                                  <FontAwesomeIcon
                                    icon="trash"
                                    color="red"
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <>
                    <div className="text-center my-4">
                      Anda Belum Memilih File
                    </div>
                  </>
                )}
                <div className="row">
                  <div className="col-12 col-lg-2 col-xl-2">
                    Attachment
                  </div>
                  <div className="col-12 col-lg-10 col-xl-10">
                    <span className="btn btn-primary btn-file">
                      <UploadFileIcon className="mx-1" /> Upload File
                      <input
                        type="file"
                        // value={selectedFile}
                        // accept=".csv,.xls,.xlsx,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                        onChange={handleFileInput}
                        multiple
                      />
                    </span>
                    <div className="my-2">
                      <span style={{ color: '#757575' }}>
                        Upload .png/.jpg/.jpeg/.docx/.xlsx/.pdf/ .csv
                        file only, with max size 10MB.
                      </span>
                    </div>
                  </div>
                </div>

                <h5>Link</h5>

                {listAttachment.length > 0 ? (
                  listAttachment.map((data, index) => {
                    return (
                      <div>
                        <div
                          className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block"
                          style={{
                            background: '#F5F5F5',
                            color: '#3267E3',
                            alignContent: 'c',
                          }}
                          key={`file-${index}`}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <span>{data.value}</span>
                            {data.id ? (
                              <span
                                className="px-2 py-2 mx-2"
                                style={{ cursor: 'pointer' }}
                                onClick={() => deleteLink(data.id)}
                              >
                                <FontAwesomeIcon
                                  icon="trash"
                                  color="red"
                                />
                              </span>
                            ) : (
                              <span
                                className="px-2 py-2 mx-2"
                                style={{ cursor: 'pointer' }}
                                onClick={() => deleteLink(data.id)}
                              >
                                <FontAwesomeIcon
                                  icon="trash"
                                  color="red"
                                />
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center my-4">
                    Anda Belum Memasukkan Link
                  </div>
                )}

                <div className="row mt-3">
                  <div className="col-12 col-lg-2 my-2">
                    Insert Google Drive Link
                  </div>
                  <div className="col-lg-6 col-12 col-sm-12 col-xs-12">
                    <input
                      className="form-control"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <div className="my-2">
                      <span style={{ color: '#757575' }}>
                        Upload .png/.jpg/.jpeg/.docx/.xlsx/.pdf/ .csv
                        file only, with max size 10MB.
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-4 col-12 col-sm-12 col-xs-12">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => _handleKeyDown(e)}
                      disabled={loadingLink}
                    >
                      {loadingLink ? (
                        <Spinner
                          color="#fff"
                          size="sm"
                          className="mr-1"
                        />
                      ) : (
                        <></>
                      )}
                      Add Link
                    </button>
                  </div>
                </div>
              </div>

              <div className="float-right">
                {/* <Button onClick={() => (state.button = 1)} id="cancel-save2" type="submit" className='mr-2' disabled={updating} color="primary">

                  &nbsp;
                  <span className="d-md-inline">
                    <SaveDraftIcon className='mr-1' />
                    Save As Draft
                  </span>
                </Button> */}
                {/* <SaveToDraftButton /> */}
                &nbsp;
                <Button
                  onClick={() => (state.button = 2)}
                  name="next"
                  color="primary"
                  id="save-entity"
                  type="submit"
                  className="pb-2"
                  disabled={updating || !contentHTML}
                >
                  <span className="mr-1">Next</span>
                  <ArrowRightIconWhite />
                </Button>
                {/* <SubmitButton /> */}
              </div>
            </AvForm>
          )}
        </Col>
      </Row>

      <div>
        &nbsp;
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>{' '}
        <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>{' '}
        <br></br> <br></br>
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  documentEntity: storeState.document.entity,
  loading: storeState.document.loading,
  loadingDepartement: storeState.document.loadingdepartement,
  loadingDetail: storeState.document.loadingdetail,
  updating: storeState.document.updating,
  updateSuccess: storeState.document.updateSuccess,
  deptList: storeState.document.deptList,
  detaildocument: storeState.document.detaildocument,
  userList: storeState.document.userList,
  approvalList: storeState.document.approvalList,
  acknowledgeList: storeState.document.acknowledgeList,
  documentListCode: storeState.document.documentListCode,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getDeptList,
  getUserList,
  getApprovalList,
  getAcknowledgeList,
  getListDocumentCode,
  sendNewDocument,
  saveDocumentToDraft,
  documentPreview,
  getDocumentDetail,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentUpdate);
