import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './announcement.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import DatePicker from 'react-datepicker';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import AccountAnnounceIcon from 'app/component/announ-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';
import UploadDocumentListIcon from 'app/component/upload-document-karyawan';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
}

const initialFormData = {
  id : "0",
  type : "",
  priority : "", 
  documentSecurity : "", 
  startDate: new Date(),
  lastDate : new Date(),
  regarding : "",
  fileContent : "", 
  to : [],
  cc : [],
  acknowledge : [], 
  approval : []
}

export const Annoucement = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [showList, setShowList] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')
  const [limit, setLimit] = useState<any>(5)
  const [listAnnouncement, setListAnnouncement] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [selectedItem, setItemSelected] = useState<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<any>(false)
  const [isAdmin, setIsAdmin] = useState<Boolean>(false)
  const [isAnnounce, setIsAnnounce] = useState<Boolean>(false)

  const [formData, setFormData] = useState(initialFormData)
  const [searchByDropDown, setSearchByDropDown] = useState<any>('Search by') 
  const [filterAccountType, setfilterAccountType] = useState<any>('Account Type') 
  const [filterBankRdn, setfilterBankRdn] = useState<any>('Bank RDN')
  
  const [modalAdd, setModalAdd] = useState<any>(false)

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    if(props.location.state?.search == '') {
      setListSearch([])
      setDefault()
    } else {
      getSearch(props.location.state?.search)
    }
  
  }, [props.location.state?.search, sortType, sortPriority, sortLastModified, sortDate, page, size, totalPage]);


  useEffect(() => {
    console.log('data search', props.location.state?.search)
    // alert(props.location.state.search)
    getSearch(props.location.state?.search)
    getListAnnouncement(page, limit)
    getIsAdmin()
    getIsAnnounce()
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc')
    setSortPriority('priority.keyword,asc')
    setSortLastModified('lastApprovedByName.keyword,asc')
    setSortDate('date,asc')
  }

  const getListAnnouncement = (page, limit) => {
    axios.get(`services/documentservice/api/announcement/dashboard?page=${page}&size=${limit}`).then(res => {
      console.log(res)
      setListAnnouncement(res.data.data?.content)
      setTotalPage(res.data.data?.totalPages)
    })
  }


  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

  const getIsAdmin = () => {
    axios.get('/services/uaadocservice/api/is-admin-dms').then(res => {
        console.log('is Admin', res.data)
        setIsAdmin(res.data)
    }).catch(err => {
        setIsAdmin(false)
    })
  }

  const getIsAnnounce = () => {
    axios.get('/services/uaadocservice/api/is-user-may-announce').then(res => {
        console.log('is Admin', res.data)
        setIsAnnounce(res.data)
    }).catch(err => {
        setIsAnnounce(false)
    })
  }

  
  const getSearch = async (e = '') => {
    if(e.length > 1 ) {
      setLoadingList(true)
      axios.get(`services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`).then( async (result) => {
        console.log(result.data)
        setListSearch(result.data.content)
        setTotalPage(result.data?.totalPages)
        await delayTime(2000)
        setLoadingList(false)
      }).catch(err => {
        setListSearch([])
        setLoadingList(false)
      })
    }
  }


  const changeSortType = () => {
    if(sortType == 'typeName.keyword,asc') {
      setSortType('typeName.keyword,desc')
    } else {
      setSortType('typeName.keyword,asc')
    }
  }

  const changeSortPriority = () => {
    if(sortPriority == 'priority.keyword,asc') {
      setSortPriority('priority.keyword,desc')
    } else {
      setSortPriority('priority.keyword,asc')
    }
  }

  const changeSortLastModified = () => {
    if(sortLastModified == 'lastApprovedByName.keyword,asc') {
      setSortLastModified('lastApprovedByName.keyword,desc')
    } else { 
      setSortLastModified('lastApprovedByName.keyword,asc')
    }
  }

  const changeSortDate = () => {
    if(sortDate == 'date,asc') {
      setSortDate('date,desc')
    } else {
      setSortDate('date,asc')
    }
  }

  const goToPage = (id) => {
    setSearch('')
    history.push(`/preview/only/pdf/${id}`)
  }

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log(value)
    setPage(value)
    // getListDocumentHistori(value, limit)
    getListAnnouncement(value, limit)
  }

  const changeShow = (e) => {
    // alert(e.target.value)
    let show = e.target.value
    console.log(limit)
    setPage(1)
    setLimit(show)
    getListAnnouncement(1, show)
    // getListDocumentHistori(1, show)
  }

  const handleClose = () => {
      setShowModal(false)
  };

  const openModalDelete = (item) => {
    setShowModal(true)
    setItemSelected(item)
  }

  const deleteFile = (id) => {
    setLoadingDelete(true)
    axios.delete(`services/documentservice/api/announcement/${id}`).then(res => {
      setLoadingDelete(false)
      toast.success('Success Delete Annoucement', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setShowModal(false)
        setItemSelected(null)
        setPage(1)
        setLimit(5)
        getListAnnouncement(1, 5)
    }).catch(err =>{
      setLoadingDelete(false)
      toast.error('Failed Delete Announcement', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setShowModal(false)
    })
  }

  const goToCreate = () => {
    history.push('/announcement/create')
  }
 
  const goToDetail = (data) => {
    // axios.post(`services/documentservice/api/notification-user/mark-has-read?notificationId=${data.notificationId}`).then((res) => {
    //   console.log('res')
    //   history.push(`/verification/view/${data.id}`)
    // }).catch((err) => {
    //   history.push(`/verification/view/${data.id}`)
    // })
  }

  const goToView = (id) => {
    history.push(`/announcement/edit/${id}`)
  }


  const handleSearchBy=(e)=>{
    console.log('SELECTED', e);
    setSearchByDropDown(e);

  }

  const handleFilterAccountType= (e)=>{
    console.log('SELECTED', e);
    setfilterAccountType(e);
  }

  const handleFilterBankRdn= (e)=>{
    console.log('SELECTED', e);
    setfilterBankRdn(e);
  }
 
  return (
    <div className="px-5 pt-4" style={{height: '100%', background:'#fff'}}>

    <div className="row">
      <div className="col-12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h1 className="mb-0 font-size-28">Client Report</h1>
        </div>
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h1 className="mb-0 font-size-14">New CMS Dashboard</h1>
        </div>
      </div>
    </div>

    {/* {
      isAdmin || isAnnounce ?  <div className="btn btn-primary rounded-lg" onClick={() => goToCreate()}>
      <AccountAnnounceIcon className="mr-1"/> Create Announcement
    </div> : <></>
    } */}
  
  <div className='row mb-4'>
      <div className='col-1'>
        {/* <div className="dropdown d-inline-block"> */}
          {/* <button className="btn btn-light dropdown-toggle waves-effect" data-toggle="dropdown" type="button" id="dropdownMenuButton1" aria-haspopup="true" aria-expanded="false" onMouseOver={() => setDropdown()}>
            Search by
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><a className="dropdown-item" href="#">Something else here</a></li>
          </ul> */}
            <DropdownButton id="dropdown-basic-button" className='' title={searchByDropDown ? searchByDropDown : 'Search by' } onSelect={handleSearchBy} >
              <Dropdown.ItemText>Select Search</Dropdown.ItemText>
              <Dropdown.Divider className='mx-4'></Dropdown.Divider>
              <Dropdown.Item eventKey="NIK">NIK</Dropdown.Item>
              <Dropdown.Item eventKey="YP ID">YP ID</Dropdown.Item>
              <Dropdown.Item eventKey="SID">SID</Dropdown.Item>
              <Dropdown.Item eventKey="Name">Customer Name</Dropdown.Item>
              <Dropdown.Item eventKey="Username">Username</Dropdown.Item>
              <Dropdown.Item eventKey="Email">Email</Dropdown.Item>
              <Dropdown.Item eventKey="User ID">User ID</Dropdown.Item>
              <Dropdown.Item eventKey="Phone">Phone Number</Dropdown.Item>
            </DropdownButton>
          
        {/* </div> */}
      </div>
      <div className="app-search col-9">
          <div className="position-relative">
            <input type="text" className="form-control" placeholder="Enter value here"/>
            <span className="bx bx-search-alt"></span>
            {/* {
              search ?  <div style={{position: 'absolute', top: '20%', right: '0.5%'}} onClick={() => clearSearch()}>
              <ClearSearch />
              </div> : <></>
            } */}
          </div>
        </div>
      <div className='col-2'> 
        <a className='btn btn-primary w-100 text-white'>Search Customer</a>
      </div>      
    </div>

    <div className='row'>
      <div className="col-4">
        <h5>Filter</h5>
      </div>
      <div className="col-8">
        <h5>Date</h5>
      </div>
    </div>
    <div className="row">
      <div className="col-4">
        <div className="page-title-box d-flex align-items-center">
        
        <DropdownButton id="dropdown-basic-button" className='mr-3' title={filterAccountType ? filterAccountType : 'Account Type' } onSelect={handleFilterAccountType}>
            <Dropdown.ItemText>Select Account Type</Dropdown.ItemText>
            <Dropdown.Divider className='mx-4'></Dropdown.Divider>
            <Dropdown.Item eventKey="All">All</Dropdown.Item>
            <Dropdown.Item eventKey="Marjin">Marjin</Dropdown.Item>
            <Dropdown.Item eventKey="Syariah">Syariah</Dropdown.Item>
            <Dropdown.Item eventKey="Reguler">Reguler</Dropdown.Item>
          </DropdownButton>
          <DropdownButton id="dropdown-basic-button" className='mr-3' title={filterBankRdn ? filterBankRdn : 'Bank RDN' } onSelect={handleFilterBankRdn}>
            <Dropdown.ItemText>Select Bank RDN</Dropdown.ItemText>
            <Dropdown.Divider className='mx-4'></Dropdown.Divider>
            <Dropdown.Item eventKey="All">All</Dropdown.Item>
            <Dropdown.Item eventKey="BCA">BCA</Dropdown.Item>
            <Dropdown.Item eventKey="BRI">BRI</Dropdown.Item>
            <Dropdown.Item eventKey="Mandiri">Mandiri</Dropdown.Item>
            <Dropdown.Item eventKey="Sinarmas">Sinarmas</Dropdown.Item>
          </DropdownButton>
        {/* <a className="btn btn-light rounded-lg">Account Type</a> */}
        {/* <a className="btn btn-light">Bank RDN</a> */}
        <a id="dropdown-basic-button" className='btn mr-3'>Reg Date</a>
        <a id="dropdown-basic-button" className='btn mr-3'>Status</a> 
        </div>
      </div>
      <div className="col-2">
        <div className="row">
          {/* <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
              <div className='font-weight-bold' style={{display: 'flex', justifyContent: 'center', alignSelf: 'center'}}>
                
                      Exp Date 
                
              </div>
          </div> */}
          <div className="col-6">
            <DatePicker  
            id="dropdown-basic-button" className='btn'
              // dateFormat="MMMM d, yyyy h:mm aa"
              // dateFormat="d MMMM yyyy - h:mm aa"
              dateFormat="d/MM/yyyy"
              // minDate={new Date()}  
              selected={formData.startDate}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  startDate: e
                })
              }}
            />
          
          </div>
          <div className="col-6">
            <DatePicker  
            id="dropdown-basic-button" className='btn'
              // dateFormat="MMMM d, yyyy h:mm aa"
              // dateFormat="d MMMM yyyy - h:mm aa"
              dateFormat="d/MM/yyyy"
              // minDate={new Date()}  
              selected={formData.lastDate}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  lastDate: e
                })
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-4">
      <a className="btn btn-primary mx-2" href='/content/images/home/testpdf.pdf' target='_blank'>
        {' '}Download PDF
      </a>
      <a className="btn btn-danger mx-2" href='/content/images/home/testpdf.pdf' target='_blank'>
        {' '}Export
      </a>
      </div>
      
    </div>
    


              <div className="my-3 bg-light">
                  <table className="table table-centered table-nowrap mb-0 table-responsive">
                    <thead className="" style={{background: '#fff', borderTopLeftRadius: 30, borderTopRightRadius: 30}}>
                      <tr>
                        <th className='py-4 px-4'>Reg Date</th>
                        <th className='py-4 px-4'>YP ID</th>
                        <th className="px-4">Cust Name</th>
                        <th className="px-4">Recommender</th>
                        <th className="px-4">Opening Branch</th>
                        <th className="px-4">RDN</th>  
                        {/* <th className="px-4">Status CL & FPR</th>   */}
                        <th className="px-4 text-center" style={{width: '4%'}}>Action</th>  
                      </tr>
                    </thead>
                    <tbody className="" style={{background: '#fff'}}>
                      {
                        listAnnouncement.map((item, index) => {
                          return (
                            <tr className="" style={item.isRead ? {background: '#FFF', cursor: 'pointer'} : {background: '#FFF4EB', cursor: 'pointer'}}>
                              <td className=""  onClick={() => goToDetail(item)}>
                              05/08/22 11:14
                              </td>
                              <td className="" style={{wordBreak: 'break-all', minWidth: '100px', maxWidth: '150px', whiteSpace: 'pre-wrap'}}  onClick={() => goToDetail(item)}>
                              {/* {item?.postedDateFormat} */}
                              131001
                              </td>
                              <td className="" style={{wordBreak: 'break-all', minWidth: '200px', maxWidth: '2500px', whiteSpace: 'pre-wrap'}}   onClick={() => goToDetail(item)}> 
                              {/* {item?.title} */}
                              Marilyn Baptista
                              </td>
                              <td className=""  onClick={() => goToDetail(item)}>  
                                {item?.senderEmployeeName}
                              </td>
                              <td className="" style={{wordBreak: 'break-all', minWidth: '100px', maxWidth: '250px', whiteSpace: 'pre-wrap'}}   onClick={() => goToDetail(item)}> 
                              {/* {item?.title} */}
                              { index % 2 == 1 ? <>
                                BSD
                              </> : <>
                                { item.canDelete || item.canEdit ? <>
                                  TAMAN ANGGREK
                                </> : <>
                                  ROXY
                                </>
                                }
                              </>
                        }
                              </td>
                              <td className=""  onClick={() => goToDetail(item)}>  
                                {/* {item?.senderBranchName} - {item?.senderEmployeeName} */}
                                { item.canDelete || item.canEdit ? <>
                                  Bank BCA
                              </> : <>
                              Bank Sinarmas
                              </>
                              }
                              </td>
                              {/* <td className=""  onClick={() => goToDetail(item)}>  
                              { item.canDelete || item.canEdit ? <>
                                Succes by KYC
                              </> : <>
                                Succes by KYC
                              </>
                              }
                              </td> */}
                             
                              <td className="">  
                                <a className="btn btn-primary mx-2" href='/content/images/home/testpdf.pdf' target='_blank'>
                                  {' '}Download
                                </a>
                                <a className="btn btn-danger mx-2" href='/content/images/home/testpdf.pdf' target='_blank'>
                                  {' '}Export
                                </a>
                              </td>
                            
                            </tr>
                          )
                        })
                      }
                    
                   
                    
                    </tbody>
                  </table>
                </div>


                <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
              <div className="form-group row">

                    <label htmlFor="priority" className="col-sm-1 col-form-label">Show</label>
                    
                    <div className="" style={{width: '80px'}}>
                    <select name="show" value={limit} className="custom-select" onChange={(e) => changeShow(e)}>
                        <option value="2">2</option>
                        <option value="5">5</option>
                        <option value="10" selected>10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                    </select>
                    
                    </div>
                    <label htmlFor="priority" className="col-sm-2 col-form-label">Entries</label>

                    </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6" style={{justifyContent: 'end', display: 'flex'}}>
              
              {/* <Page currentPage={page} totalPage={listDocumentHistori.totalPages} last={listDocumentHistori.last} first={listDocumentHistori.first} changePage={(page) => changePage(page)}/> */}
              <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={changePage}/>

              </div>
            </div>
            <Modal isOpen={showModal} toggle={handleClose} size="lg" centered onClosed={() => {
              setShowModal(false)
              setItemSelected(null)
            }}>
              <ModalHeader toggle={handleClose} style={{borderBottom: 'none'}}>
              </ModalHeader>
              <ModalBody id="documenttestApp.document.delete.question">
                  <h3 className="text-center">
                    Are you sure want to delete <span className="font-weight-bold">"{selectedItem?.title}"</span> announcement ?
                  </h3>
                  <div className="text-center mt-5">
                    <button className="btn btn-success mx-2 px-4" onClick={() => deleteFile(selectedItem?.id)} disabled={loadingDelete}>
                      {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' />  : <></>}   Yes
                    </button >
                    <button disabled={loadingDelete} className="btn btn-secondary mx-2" onClick={() => {
                      setShowModal(false);
                      setItemSelected(null)
                    }}>
                                Cancel
                    </button >
                  </div>
              </ModalBody>
              <ModalFooter style={{borderTop: 'none'}}>

              </ModalFooter>
            </Modal>
                
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
 
});


const mapDispatchToProps = {
 
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(Annoucement);
