import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Document from './document';
import Sent from './sent';
import Inbox from './inbox';
import Approval from './approval';
import Acknowledge from './acknowledge/';
import Preview from './preview';
import Histori from './history/Histori';
import HistoriDetail from './history/HistoriDetail';
import NotFound from './document/NotFound';
import Search from './search/Search';
import { Annoucement } from './announcement/Announcement';
import AnnouncementCreate from './announcement/AnnouncementCreate';
import AnnouncementEdit from './announcement/AnnouncementEdit';
import AnnouncementView from './announcement/AnnouncementView';
import Notification from './notification/Notification';
import KaryawanAdd from './karyawan/Karyawan-add';
import Karyawan from './karyawan/Karyawan';
import KaryawanAddManual from './karyawan/Karyawan-add-manual';
import Group from './group/Group';
import GroupDetail from './group/GroupDetail';
import Clfpr from './clfpr/Clfpr';
import { AML } from './aml/aml';
import { AMLView } from './aml/amlView';
import { VerificationDetail } from './document/verification-detail';
import { DonationByOrder } from './donation/DonationByOrder';
import { DonationByMerchant } from './donation/DonationByMerchant';
import { ShippingUpload } from './shipping/ShippingUpload';

/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}verification/list`} component={Document} />
      <ErrorBoundaryRoute
        path={`${match.url}verification/view/:id`}
        component={VerificationDetail}
      />

      <ErrorBoundaryRoute
        path={`${match.url}sent`}
        component={Sent}
      />
      <ErrorBoundaryRoute
        path={`${match.url}inbox`}
        component={Inbox}
      />
      <ErrorBoundaryRoute
        path={`${match.url}approval`}
        component={Approval}
      />
      <ErrorBoundaryRoute
        path={`${match.url}acknowledge`}
        component={Acknowledge}
      />
      <ErrorBoundaryRoute
        path={`${match.url}preview`}
        component={Preview}
      />
      <ErrorBoundaryRoute
        path={`${match.url}admin-reports`}
        exact
        component={Histori}
      />
      <ErrorBoundaryRoute
        path={`${match.url}history/detail/:id`}
        component={HistoriDetail}
      />
      <ErrorBoundaryRoute
        path={`${match.url}cl-fpr`}
        exact
        component={Clfpr}
      />

      <ErrorBoundaryRoute
        path={`${match.url}aml`}
        exact
        component={AML}
      />
      <ErrorBoundaryRoute
        path={`${match.url}aml/view/:id`}
        exact
        component={AMLView}
      />

      <ErrorBoundaryRoute
        path={`${match.url}search`}
        component={Search}
      />
      <ErrorBoundaryRoute
        path={`${match.url}client-reports`}
        component={Annoucement}
      />
      <ErrorBoundaryRoute
        path={`${match.url}donation/order`}
        component={DonationByOrder}
      />
      <ErrorBoundaryRoute
        path={`${match.url}donation/merchant`}
        component={DonationByMerchant}
      />
      <ErrorBoundaryRoute
        path={`${match.url}shipping/upload`}
        component={ShippingUpload}
      />
      <ErrorBoundaryRoute
        path={`${match.url}announcement/create`}
        component={AnnouncementCreate}
      />
      <ErrorBoundaryRoute
        path={`${match.url}announcement/edit/:id`}
        component={AnnouncementEdit}
      />
      <ErrorBoundaryRoute
        path={`${match.url}customer-verification/view/:id`}
        component={AnnouncementView}
      />

      <ErrorBoundaryRoute
        path={`${match.url}notification`}
        component={Notification}
      />
      <ErrorBoundaryRoute
        path={`${match.url}karyawan/add`}
        component={KaryawanAddManual}
      />
      <ErrorBoundaryRoute
        path={`${match.url}karyawan/upload`}
        component={KaryawanAdd}
      />
      <ErrorBoundaryRoute
        path={`${match.url}karyawan`}
        component={Karyawan}
      />
      <ErrorBoundaryRoute
        path={`${match.url}group/detail/:id`}
        component={GroupDetail}
      />
      <ErrorBoundaryRoute
        path={`${match.url}group`}
        component={Group}
      />
      <ErrorBoundaryRoute
        path={`${match.url}/404`}
        component={NotFound}
      />

      {/* <ErrorBoundaryRoute  path={`${match.url}/404`} component={NotFound}/> */}
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
