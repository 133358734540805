import * as React from 'react';
import { SVGProps } from 'react';

const FolderOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="219"
    height="97"
    viewBox="0 0 219 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 9C0 4.02944 4.02944 0 9 0H210C214.971 0 219 4.02944 219 9V96.0029H0V9Z"
      fill="#FFF4EB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M147.137 30.0979C148.276 27.6791 150.736 26 153.587 26C156.762 26 159.456 28.0781 160.378 30.9541C162.431 31.0788 164.085 32.7246 164.061 34.8691C164.061 35.0686 142.939 35.0105 142.939 34.8691C142.931 31.6191 144.045 30.073 146.613 30.073C146.788 30.0647 146.962 30.0813 147.137 30.0979Z"
      fill="#FBDEC9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M79.3857 15.9266C77.7399 12.4272 74.1823 10 70.0677 10C65.4794 10 61.5893 13.009 60.2593 17.1568C57.2919 17.3397 54.9063 19.717 54.9395 22.8174C54.9395 23.1084 85.4537 23.0169 85.4537 22.8174C85.4703 18.1294 83.8577 15.8851 80.1505 15.8851C79.8928 15.8851 79.6351 15.8934 79.3857 15.9266Z"
      fill="#FBDEC9"
    />
    <path
      d="M109.5 79.0031C122.464 79.0031 132.974 77.8643 132.974 76.4596C132.974 75.0548 122.464 73.916 109.5 73.916C96.5358 73.916 86.0263 75.0548 86.0263 76.4596C86.0263 77.8643 96.5358 79.0031 109.5 79.0031Z"
      fill="#FBDEC9"
    />
    <path
      d="M91.4196 76.445L88.6849 54.8249C88.5519 53.7693 89.3166 52.8965 90.3806 52.8965H113.414L91.4196 76.445Z"
      fill="#FBDEC9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M92.0009 76.3705L89.2662 54.7504C89.1748 54.0522 89.6818 53.487 90.38 53.487H101.676C102.001 53.487 102.267 53.221 102.267 52.8968C102.267 52.5726 102.001 52.3066 101.676 52.3066H90.3883C88.9586 52.3066 87.9279 53.4703 88.1108 54.8917L90.8455 76.5118C90.8871 76.836 91.178 77.0604 91.5022 77.0189C91.818 76.9856 92.0425 76.6947 92.0009 76.3705Z"
      fill="#CC7633"
    />
    <path
      d="M114.452 46.9367H111.784C111.858 47.2525 111.908 47.585 111.908 47.9258V51.5084C111.908 53.5615 110.396 55.2489 108.542 55.2489C106.688 55.2489 105.175 53.5698 105.175 51.5084V47.9258C105.175 47.585 105.217 47.2525 105.3 46.9367H102.632C100.579 46.9367 99.8889 45.5818 101.111 43.9276L106.331 36.8207C107.544 35.1665 109.539 35.1665 110.753 36.8207L115.973 43.9276C117.195 45.5818 116.513 46.9367 114.452 46.9367Z"
      fill="#FBDEC9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M104.611 47.5233H102.632C101.319 47.5233 100.471 47.0163 100.122 46.318C99.7646 45.6198 99.8561 44.639 100.637 43.575L102.208 41.4388C102.4 41.1811 102.765 41.1229 103.031 41.3141C103.289 41.5053 103.347 41.871 103.156 42.137L101.585 44.2732C101.144 44.8717 100.97 45.3954 101.169 45.7861C101.369 46.1767 101.893 46.3513 102.632 46.3513H105.301C105.483 46.3513 105.65 46.4344 105.766 46.5757C105.874 46.717 105.916 46.8999 105.874 47.0828C105.808 47.3488 105.766 47.6314 105.766 47.9306V51.5132C105.766 53.2255 106.996 54.6635 108.542 54.6635C110.088 54.6635 111.319 53.2255 111.319 51.5049C111.319 51.1807 111.585 50.9147 111.909 50.9147C112.233 50.9147 112.499 51.1807 112.499 51.5049C112.499 53.8988 110.704 55.8272 108.551 55.8272C106.398 55.8272 104.602 53.8988 104.602 51.5049V47.9223C104.594 47.7893 104.602 47.6563 104.611 47.5233Z"
      fill="#CC7633"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M106.805 37.1675C107.287 36.5109 107.91 36.1618 108.542 36.1618C109.174 36.1618 109.797 36.5109 110.279 37.1675L115.499 44.2745C115.94 44.873 116.114 45.3966 115.915 45.7873C115.715 46.178 115.192 46.3525 114.452 46.3525C114.128 46.3525 113.862 46.6185 113.862 46.9427C113.862 47.2669 114.128 47.5329 114.452 47.5329C115.765 47.5329 116.613 47.0258 116.962 46.3276C117.32 45.6294 117.228 44.6485 116.447 43.5846L111.227 36.4776C110.495 35.4802 109.514 34.998 108.542 34.998C107.569 34.998 106.588 35.4802 105.857 36.4776C105.666 36.7353 105.724 37.1094 105.982 37.3005C106.239 37.4834 106.613 37.4252 106.805 37.1675Z"
      fill="#CC7633"
    />
    <path
      d="M91.4198 76.4445H125.965L130.363 51.4746H113.613C112.55 51.4746 111.145 52.1728 110.505 53.029L107.396 57.1685H97.7537C96.6898 57.1685 95.6175 58.0163 95.3848 59.0637L91.4198 76.4445Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M107.403 56.5723H97.7605C96.4389 56.5723 95.1089 57.6279 94.818 58.9163L92.9727 66.9958C92.8979 67.3117 93.0974 67.6275 93.4132 67.7023C93.7291 67.7771 94.045 67.5777 94.1198 67.2618L95.9651 59.1823C96.1479 58.3926 96.9542 57.7443 97.7688 57.7443H107.411C107.735 57.7443 108.001 57.4783 108.001 57.1541C107.985 56.8383 107.727 56.5723 107.403 56.5723Z"
      fill="#CC7633"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M129.663 52.0651H113.612C113.288 52.0651 113.022 51.7991 113.022 51.4749C113.022 51.1508 113.288 50.8848 113.612 50.8848H130.369C130.544 50.8848 130.71 50.9596 130.818 51.0926C130.926 51.2256 130.976 51.4001 130.943 51.5747L126.546 76.5446C126.496 76.8272 126.255 77.0267 125.964 77.0267H91.4182C91.094 77.0267 90.828 76.7607 90.828 76.4365C90.828 76.1124 91.094 75.8464 91.4182 75.8464H125.473L129.663 52.0651Z"
      fill="#CC7633"
    />
    <path
      d="M114.387 74.1128L115.326 68.7598H124.968L124.029 74.1128H114.387Z"
      fill="#FBDEC9"
    />
  </svg>
);

export default FolderOpen;
